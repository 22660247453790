export enum ROUTES {
	forbidden = 'forbidden',
	main = '/',
	login = 'login',
	resetPassword = 'reset-password',
	setPassword = 'set-password',
	users = 'users',
	clients = 'clients',
	services = 'services',
	providers = 'providers',
	categories = 'categories',
	addresses = 'addresses',
	aliasesByProvider = 'aliases-by-provider',
	streets = 'streets',
	streetsType = 'streets-type',
	paymentStatus = 'payment-status',
	rejectedData = 'rejected-data',
	profileSetups = 'profile-setups',
	reportData = 'reports',
	dataSettings = 'data-settings',
	notFound = 'not-found',
	singleReceipts = 'single-receipts',
	receiptPayDeepLink = '/pay-by-params',
	addressDetails = 'addresses/:id',
	usersDetails = 'users/:id',
	serviceDetails = 'services/:id',
	paymentStatusDetails = 'payment-status/:id',
	singleReceiptDetails = 'single-receipts/:id',
	otherReceipts = 'other-receipts',
	roles = 'roles',
	rights = 'rights',
	rolesDetails = 'roles/:id',
}
