import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { ICreateRoleRequest } from "types/roles-and-rights.interface";

export const AddRole = () => {
	const { getFieldProps, errors, touched } = useFormikContext<ICreateRoleRequest>();

	return (<>
		<TextField
			variant="outlined"
			label="Назва"
			{...getFieldProps('name')}
			helperText={errors.name && touched.name ? errors.name : ''}
		/>
		<TextField
			variant="outlined"
			label="Опис"
			{...getFieldProps('description')}
			helperText={errors.description && touched.description ? errors.description : ''}
		/>
	</>)

}