import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { ReactComponent as StartIcon } from 'assets/icons/start-icon.svg';
import { getMonthOptions, getYearOptions } from './config';
import { CustomSelect } from 'components/elements';
import ConfirmationModal from 'components/modules/ConfirmationModal';
import { ReportList } from './ReportList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { generateReports, getReportsLoadingSelector } from 'store/reports';
import { REPORT_STATUS } from 'utils/enums';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { PROVIDERS_DATA, PROVIDER_NAMES } from 'utils/constants';
import SocketContext from 'socket/ReportsSocket/context';
import { IGenerateReportsResponse } from 'types';

export const ReportDataPage: FC = () => {
	const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
	const [selectedYear, setSelectedYear] = useState<string>(dayjs().year().toString());
	const [selectedMonth, setSelectedMonth] = useState<string>(dayjs().month().toString());
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [modalMessage, setModalMessage] = useState<string>('');
	const [isDuplicateResponse, setIsDuplicateResponse] = useState<boolean>(false);
	const { generate } = useContext(SocketContext);

	const isLoading = useAppSelector(getReportsLoadingSelector);
	const dispatch = useAppDispatch();

	const monthOptions = useMemo(() => (selectedYear ? getMonthOptions(selectedYear) : []), [selectedYear]);
	const yearOptions = getYearOptions();

	const isDisabledDate = useMemo(() => selectedMonth === dayjs().format('MM'), [selectedMonth]);
	const isDisabled = useMemo(
		() => isDisabledDate || !selectedProviders.length || generate === REPORT_STATUS.START,
		[isDisabledDate, selectedProviders.length, generate]
	);

	useEffect(() => {
		setSelectedMonth(monthOptions[monthOptions.length - 1]?.value);
	}, [monthOptions]);

	const handleProviderChange = (value: string) => {
		setSelectedProviders((prevProviders) => {
			if (prevProviders.includes(value)) {
				return prevProviders.filter((prevProvider) => prevProvider !== value);
			} else {
				return [...prevProviders, value];
			}
		});
	};

	const handleGenerateReport = async () => {
		if (selectedYear && selectedMonth) {
			const response = await dispatch(
				generateReports({
					providersArray: selectedProviders,
					month: selectedMonth,
					year: selectedYear
				})
			);

			handleGenerateDuplicateReports(response.payload as IGenerateReportsResponse);

			setIsModalOpen(true);
		}
	};

	const handleGenerateDuplicateReports = (response: IGenerateReportsResponse) => {
		if (response && response.message && response.message.includes('Report exist for this type:')) {
			const providersString = response.message.split(':')[1];
			const duplicateProviders = providersString.split(',').map((provider) => provider.trim());
			const duplicateProviderNames = duplicateProviders.map(
				(provider: string) => PROVIDER_NAMES[provider as keyof typeof PROVIDER_NAMES]
			);
			setIsDuplicateResponse(true);
			setModalMessage(`Ви намагаєтесь повторно сформувати звіти для: ${duplicateProviderNames.join(', ')}.`);
		} else {
			setIsDuplicateResponse(false);
			setModalMessage(`Після завершення процесу формування він буде доступний у колонці “Сформовані звіти”.
			Ви не зможете розпочати формування нового звіту до завершення процесу поточного формування `);
		}
	};

	const handleInfoClose = () => {
		setIsModalOpen(false);
	};

	return (
		<Box className={styles.wrapper}>
			<Box className={styles.wrapper__container} minWidth="56%">
				<Box className={styles.content}>
					<Typography variant="h4" maxWidth="80%">
						Оберіть провайдерів, для яких потрібно сформувати звіт та вкажіть потрібний період
					</Typography>
					<Box className={styles.date}>
						<CustomSelect
							placeholder={selectedMonth}
							value={selectedMonth}
							options={monthOptions}
							name="Month"
							onChange={(event) => setSelectedMonth(event.target.value)}
						/>
						<CustomSelect
							placeholder={selectedYear}
							value={selectedYear}
							options={yearOptions}
							name="Year"
							onChange={(event) => setSelectedYear(event.target.value)}
						/>
					</Box>
					{PROVIDERS_DATA.map((provider, index) => (
						<Box key={provider.name}>
							<Box className={styles.content__providers}>
								<input
									type="checkbox"
									onChange={() => handleProviderChange(provider.value)}
									checked={selectedProviders.includes(provider.value)}
									className={styles.checkbox}
								/>
								<Typography variant="body2">{provider.name}</Typography>
							</Box>
							<Box className={styles.page__details_divider}>{PROVIDERS_DATA.length - 1 !== index && <Divider />}</Box>
						</Box>
					))}
				</Box>
				<Button
					variant="outlined"
					color="primary"
					startIcon={!isLoading && <StartIcon opacity={isDisabled ? 0.3 : 1} />}
					disabled={isDisabled}
					className={styles.action}
					onClick={handleGenerateReport}
				>
					{!isLoading ? 'Сформувати звіт' : <CircularProgress size={20} color="success" />}
				</Button>
			</Box>
			<ReportList />
			<ConfirmationModal
				open={isModalOpen}
				title={isDuplicateResponse ? 'Опа! Клікнули та не подивились!' : 'Звіт формується'}
				message={modalMessage}
			>
				<Button variant="rounded" onClick={handleInfoClose}>
					Зрозуміло
				</Button>
			</ConfirmationModal>
		</Box>
	);
};
