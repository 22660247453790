import { OTHER_RECEIPTS_HEADER_TITLES } from 'utils/constants';
import { OTHER_RECEIPTS_FIELDS_NAME } from 'utils/enums';
import dayjs from 'dayjs';
import axios from 'axios';
import {IReportsDataList} from "../../types";

export const COLUMNS_CONFIG = [
	{
		field: OTHER_RECEIPTS_FIELDS_NAME.PROVIDER_SERVICE,
		headerName: OTHER_RECEIPTS_HEADER_TITLES.PROVIDER_SERVICE,
		flex: 1.8,
	},
	{ field: OTHER_RECEIPTS_FIELDS_NAME.REQ_DATA, headerName: OTHER_RECEIPTS_HEADER_TITLES.REQ_DATA, flex: 2 },
	{ field: OTHER_RECEIPTS_FIELDS_NAME.USER_ID, headerName: OTHER_RECEIPTS_HEADER_TITLES.USER_ID, flex: 2 },
	{
		field: OTHER_RECEIPTS_FIELDS_NAME.AMOUNT,
		headerName: OTHER_RECEIPTS_HEADER_TITLES.AMOUNT,
		flex: 0.8
	},
	{ field: OTHER_RECEIPTS_FIELDS_NAME.FEE, headerName: OTHER_RECEIPTS_HEADER_TITLES.FEE, flex: 0.8 },
	{ field: OTHER_RECEIPTS_FIELDS_NAME.PAID_AMOUNT, headerName: OTHER_RECEIPTS_HEADER_TITLES.PAID_AMOUNT, flex: 0.8 },
	{ field: OTHER_RECEIPTS_FIELDS_NAME.STATUS, headerName: OTHER_RECEIPTS_HEADER_TITLES.STATUS, flex: 1 },
	{ field: OTHER_RECEIPTS_FIELDS_NAME.CARD, headerName: OTHER_RECEIPTS_HEADER_TITLES.CARD, flex: 0.8 },
	{
		field: OTHER_RECEIPTS_FIELDS_NAME.PAID_AT,
		headerName: OTHER_RECEIPTS_HEADER_TITLES.PAID_AT,
		flex: 1.2,
	},
];

export const shouldDisableMonth = (date: dayjs.Dayjs) => {
	return date.isBefore(dayjs('2023-11'), 'month');
};

export const disablePastYear = (date: dayjs.Dayjs) => {
	return date.isBefore(dayjs('2023'), 'year');
};

export const downloadReportPdf = async (report: IReportsDataList) =>  {
	if(report.s3URL) {
		try {
			const response = await axios.get(report.s3URL, { responseType: 'blob' });
			const blobUrl = URL.createObjectURL(response.data);
			const downloadLink = document.createElement('a');
			downloadLink.href = blobUrl;
			downloadLink.download = `${report.id}.pdf`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			window.open(blobUrl, '_blank');
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(blobUrl);
		} catch (error) {
			console.log('Error download report :', error);
		}
	}
}
