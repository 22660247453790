import { FC, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ReactComponent as DatePickerIcon } from 'assets/icons/date-picker.svg';

import styles from './styles.module.scss';

export interface CustomDatePickerProps {
	className?: string;
	name: string;
	format?: string;
	shouldDisableMonth?: (day: Dayjs) => boolean;
	shouldDisableYear?: (day: Dayjs) => boolean;
	onChange: (date: Date | null) => void;
	views: Array<'year' | 'month' | 'day'>;
}

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
	className = '',
	name,
	shouldDisableMonth,
	shouldDisableYear,
	onChange,
	format,
	views,
	...props
}) => {
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

	const handleDateChange = (date: Dayjs | null) => {
		setSelectedDate(date);
		if (onChange) {
			onChange(date ? date.toDate() : null);
		}
	};
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
			<DatePicker
				views={views}
				value={selectedDate}
				className={`${styles.datePicker} ${className}`}
				slots={{ openPickerIcon: DatePickerIcon }}
				onChange={handleDateChange}
				disableFuture
				shouldDisableYear={shouldDisableYear}
				shouldDisableMonth={shouldDisableMonth}
				slotProps={{
					textField: {
						size: 'small',
						placeholder: dayjs().format(`${format}`),
						name,
					},
				}}
				format={format}
				{...props}
			/>
		</LocalizationProvider>
	);
};
