import { MIN_LENGTH } from 'utils/constants';
import { VALIDATION_ERROR } from 'utils/enums';
import * as yup from 'yup';

export const stringMinLength = yup
	.string()
	.test('first character', VALIDATION_ERROR.FIRST_CHARACTER, (value) => !/^[-']/.test(value as string))
	.matches(/^[a-zA-Zа-яА-ЯіІ'][a-zA-Zа-яА-ЯіІ'-]*$/, {
		message: VALIDATION_ERROR.SPECIAL_CHARACTERS,
		excludeEmptyString: true,
	})
	.min(MIN_LENGTH, VALIDATION_ERROR.MIN_LENGTH)
	.required(VALIDATION_ERROR.REQUIRED)
	.nullable();

export const createUserSchema = yup.object().shape({
	firstName: stringMinLength,
	lastName: stringMinLength,
	email: yup.string().email(VALIDATION_ERROR.EMAIL).required(VALIDATION_ERROR.REQUIRED),
	type: yup.string().required(VALIDATION_ERROR.REQUIRED),
});
