import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { DetailsContent } from 'components/modules/DetailsContent';
import { DetailsHeader } from 'components/modules/DetailsHeader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserProfileSelector, updateUserProfile, userLogout } from 'store/user';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as TrashService } from 'assets/icons/trash.svg';
import { ReactComponent as SignOut } from 'assets/icons/logout.svg';
import { USER_PROFILE_KEYS } from 'utils/enums';
import { USER_PROFILE_LOCKED_FIELDS, USER_PROFILE_TITLES } from './config';
import { IUserProfileResponse } from 'types';
import styles from './index.module.scss';
import Loading from 'components/modules/Loading';
import { ROUTES } from 'routes';

export const UserProfileDetails: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [profileData, setProfileData] = useState<IUserProfileResponse | null>(null);
	const [updatedUserData, setUpdatedUserData] = useState({});
	const userProfileInitialValue = useAppSelector(getUserProfileSelector);

	const handleUpdateUser = () => {
		if (profileData) {
			dispatch(updateUserProfile({ userId: profileData.userId, ...updatedUserData }));
		}
	};
	// TODO: endpoint for delete user profile

	// const handleDeleteUserProfile = () => {
	// 	if (profileData?.userId) {
	// dispatch(deleteUserProfile(profileData.userId));
	// navigate(`/${ROUTES.login}`);
	// 	}
	// };

	const handleUserLogout = () => {
		if (profileData?.userId) {
			dispatch(userLogout({ userId: profileData.userId }));
			navigate(`/${ROUTES.login}`);
		}
	};

	const handleUpdateUserData = (data: Partial<IUserProfileResponse>) => {
		setUpdatedUserData({ ...updatedUserData, ...data });
	};

	useEffect(() => {
		if (userProfileInitialValue) {
			setProfileData(userProfileInitialValue);
		}
	}, [userProfileInitialValue]);

	if (!profileData) return <Loading />;

	return (
		<Box className={styles.wrapper}>
			<DetailsHeader
				image={userProfileInitialValue?.firstName}
				firstName={userProfileInitialValue?.firstName}
				lastName={userProfileInitialValue?.lastName}
			/>
			<DetailsContent<USER_PROFILE_KEYS, IUserProfileResponse>
				detailsTitles={USER_PROFILE_TITLES}
				title="Особисті дані"
				detailsData={profileData as IUserProfileResponse}
				setDetailsData={setProfileData}
				onSetUpdatedData={handleUpdateUserData}
				lockedFields={USER_PROFILE_LOCKED_FIELDS}
			/>
			<Button
				sx={{ width: '100%' }}
				variant="outlined"
				startIcon={<RefreshIcon />}
				onClick={handleUpdateUser}
				disabled={!Object.values(updatedUserData).length}
			>
				Оновити дані
			</Button>
			<Box className={styles.actions}>
				<Button variant="outlined" startIcon={<SignOut />} onClick={handleUserLogout}>
					Вийти
				</Button>
				<Button disabled={true} variant="outlined" startIcon={<TrashService />}>
					Видалити обліковий запис
				</Button>
			</Box>
		</Box>
	);
};
