export enum STATUS_OPTIONS {
    NEW = 'NEW',
    CONFIRMED = 'CONFIRMED',
    READY = 'READY',
    PENDING = 'PENDING',
    PAID = 'PAID',
    PARTIALPAID = 'PARTIAL PAID',
    REJECTED = 'REJECTED',
    RETURNED = 'RETURNED',
    UNKNOWN = 'UNKNOWN',
}
export enum PROVIDER_OPTIONS {
    PROCARD = 'PROCARD',
    UAPAY = 'UAPAY',
    FUIB = 'FUIB',
    STOP = 'STOP',
    TAS = 'TAS',
    UNEX = 'UNEX',
}
