import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IStreetsDetails, IStreetsRequest, IStreetsResponse } from 'types';
import { getErrorResponse } from 'utils/helpers';
import { StreetsService } from 'utils/services';

export const getStreets = createAsyncThunk(
	'streetsSlice/getStreets',
	async (reqBody: IStreetsRequest, { rejectWithValue }) => {
		try {
			const streetsResponse: AxiosResponse<IStreetsResponse> = await StreetsService.getStreets(reqBody);
			return streetsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateStreets = createAsyncThunk(
	'streetsTypesSlice/updateStreets',
	async (reqBody: IStreetsDetails, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IStreetsResponse> = await StreetsService.updateStreets(reqBody);

			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
