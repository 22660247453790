import { USER_DETAILS_KEYS, USER_ROLE, USER_STATUS } from 'utils/enums';

export const USERS_HEADER_TITLES = {
	ID: 'ID',
	NAME: 'Імʼя',
	EMAIL: 'Email',
	PHONE: 'Телефон',
	STATUS: 'Статус',
	ROLE: 'Роль',
	UPDATED_AT: 'Остання активність',
};

export const USER_ROLE_TITLE = {
	ADMIN: 'Адміністратор',
	USER: 'Користувач',
};

export const USER_STATUS_TITLE = {
	ACTIVE: 'Активний',
	DELETED: 'Не активний',
	TEMPORARY_BLOCKED: 'Тимчасово заблокований',
	BLOCKED: 'Заблокований',
};

type SelectOption<T> = {
	title: string,
	value: T,
}
export type SelectOptions<T> = SelectOption<T>[]

export const USER_STATUS_OPTIONS = [
	{ title: USER_STATUS_TITLE.ACTIVE, value: USER_STATUS.ACTIVE },
	{ title: USER_STATUS_TITLE.DELETED, value: USER_STATUS.DELETED },
	{ title: USER_STATUS_TITLE.BLOCKED, value: USER_STATUS.BLOCKED},
	{ title: USER_STATUS_TITLE.TEMPORARY_BLOCKED, value: USER_STATUS.TEMPORARY_BLOCKED}
];

export const USER_ROLE_OPTIONS = [
	{ title: USER_ROLE_TITLE.ADMIN, value: USER_ROLE.ADMIN },
	{ title: USER_ROLE_TITLE.USER, value: USER_ROLE.USER },
];

export const CREATE_USER = {
	firstName: '',
	lastName: '',
	email: '',
	type: USER_ROLE.ADMIN,
};

export const USER_LOCKED_FIELDS = [ USER_DETAILS_KEYS.PHONE, USER_DETAILS_KEYS.EMAIL, USER_DETAILS_KEYS.CREATED_AT, USER_DETAILS_KEYS.LOGGED_AT];
