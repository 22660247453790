import { BasisTabPage } from 'pages/AddressDetailsPage/BasisTabPage';
import { SettingsTabPage } from 'pages/AddressDetailsPage/SettingsTabPage';
import { ROUTES } from 'routes';
import { ITab } from 'types';
import { Rights } from 'utils/enums/rights.enum';
import { CanIFn } from 'utils/hooks/use-permissions';

export const PLATFORM_TABS: {
	path: ROUTES,
	title: string,
	right: Rights,
	visible?: boolean,
}[] = [
		{ path: ROUTES.users, title: 'Користувачі', right: Rights.USERS_READ },
		{ path: ROUTES.roles, title: 'Ролі', right: Rights.ROLES_READ },
		{ path: ROUTES.rights, title: 'Права', right: Rights.RIGHTS_READ },
		{ path: ROUTES.clients, title: 'Клієнти', right: Rights.CLIENTS_READ },
		{ path: ROUTES.services, title: 'Сервіси', right: Rights.SERVICES_READ },
		{ path: ROUTES.providers, title: 'Провайдери', right: Rights.PROVIDERS_READ },
		{ path: ROUTES.paymentStatus, title: 'Платежі', right: Rights.CATEGORIES_READ }, // TODO change rights
		{ path: ROUTES.categories, title: 'Категорії', right: Rights.CATEGORIES_READ },
		{ path: ROUTES.addresses, title: 'Помешкання', right: Rights.APARTMENTS_READ },
		{ path: ROUTES.aliasesByProvider, title: 'Аліаси', right: Rights.USERS_READ }, // TODO change rights
		{ path: ROUTES.streets, title: 'Вулиці', right: Rights.STREETS_READ },
		{ path: ROUTES.streetsType, title: 'Типи вулиць', right: Rights.STREET_TYPES_READ },
		{ path: ROUTES.rejectedData, title: 'Невизначені дані', right: Rights.SPECIFY_DATA_READ },
		{ path: ROUTES.singleReceipts, title: 'Єдині квитанції', right: Rights.SINGLE_RECEIPTS_READ },
		{ path: ROUTES.otherReceipts, title: 'Інші платежі', right: Rights.OTHER_RECEIPTS_READ },
		{ path: ROUTES.reportData, title: 'Звіти', right: Rights.REPORTS_READ },
		{ path: ROUTES.dataSettings, title: 'Керування даними', right: Rights.DATA_SETTINGS_READ },
	];

export const ADDRESS_DETAILS_TABS: ITab[] = [
	{ title: 'Основне', content: <BasisTabPage /> },
	{ title: 'Налаштування', content: <SettingsTabPage /> },
];

export const withPermissions = (canI: CanIFn) => {
	PLATFORM_TABS.forEach(item => {
		item.visible = item.right ? canI(item.right) : item.visible;
	});

	return PLATFORM_TABS;
}
