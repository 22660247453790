export enum API_URL {
	USER_AUTH_LOGIN = 'login',
	USER_AUTH_LOGOUT = 'logout',
	USER_PASSWORD_RECOVERY = '/users/recovery',
	USER_PASSWORD_RECOVERY_RESET = '/users/recovery/reset',
	USER_SET_PASSWORD = '/users/set-password',
	USER_PROFILE_GET = 'profile/get',
	USER_PROFILE_UPDATE = '/profile/update',
	USER_PROFILE_DELETE = '/profile/delete',

	USERS_LIST_GET = '/users/userslist',
	USER_DETAILS_GET = '/users/user/get',
	USER_UPDATE = '/users/user/update',
	USER_DELETE = '/users/user/delete',
	USER_CREATE = '/users/add',

	USER_GARD_LIST_GET = '/card/list',
	USER_GARD_DELETE = '/card/delete',

	USER_UPDATE_STATUS_BLOCK = '/users/user/block',
	USER_UPDATE_STATUS_BLOCK_TEMPORARY = '/users/user/block/temporary',

	CLIENTS_LIST_GET = '/client/list',
	CLIENTS_CREATE = '/client/add',

	BILL_SERVICES_GET = '/billservice/services/list',
	BILL_SERVICES_CREATE = '/billservice/services/add',
	BILL_SERVICE_DETAILS_GET = '/billservice/services/details',
	BILL_SERVICE_UPDATE = '/billservice/services/update',
	BILL_SERVICE_UPDATE_FEE = '/billservice/contracts/fee/update',

	USER_APARTMENT_GET = '/billservice/user/apartment-accounts',
	PROVIDERS_LIST_GET = '/billservice/providers/list',
	CATEGORIES_LIST_GET = '/billservice/categories/list',
	CATEGORIES_UPDATE_SN = '/billservice/categories/update/bill-categories-SN',
	APARTMENT_LIST_GET = '/utility/apartmentAccount/list',
	// ADDRESSES_GET_ALL = '/utility/apartmentAccount/get-all',
	ADDRESS_GET_BY_ID = '/utility/apartmentAccount/get-with-all-data',
	ADDRESS_UPDATE_BY_ID = '/utility/apartmentAccount/update',
	APARTMENT_ACCOUNT_STREETS_GET = '/utility/apartmentAccount/getStreet',
	APARTMENT_ACCOUNT_HOUSES_GET = '/utility/apartmentAccount/getHouses',
	APARTMENT_ACCOUNT_FLATS_GET = '/utility/apartmentAccount/getFlat',
	STREETS_GET = '/utility/streets/list',
	STREETS_CREATE = '/utility/streets/create',
	STREETS_UPDATE = '/utility/streets/update',
	STREETS_TYPES_GET = '/utility/streetTypes/list',
	STREETS_TYPES_CREATE = '/utility/streetTypes/create',
	STREETS_TYPES_UPDATE = '/utility/streetTypes/update',
	ALIASES_BY_PROVIDER_LIST = '/utility/street-aliases-by-provider/list',
	ALIASES_BY_PROVIDER_CREATE = '/utility/street-aliases-by-provider/create',
	ALIASES_BY_PROVIDER_UPDATE = '/utility/street-aliases-by-provider/update',

	USER_GET_ROLE = '/users/role/get',
	USER_ROLES_LIST = '/users/role/list',
	USER_ROLES_CREATE = '/users/role/create',
	USER_ROLES_UPDATE = '/users/role/update',
	USER_ROLES_DELETE = '/users/role/delete',

	USER_RIGHTS_LIST = '/users/right/list',
	USER_RIGHTS_CREATE = '/users/right/create',
	USER_RIGHTS_GET = '/users/right/getByName',
	USER_RIGHTS_UPDATE = '/users/right/update',
	USER_RIGHTS_DELETE = '/users/right/remove',

	REPORTS_GENERATE = '/billservice/report/provider-payments/monthly',
	REPORTS_LIST_GET = '/report/get-csv-report',
	REPORT_LIST_PAYMENT_GET = '/report/paymentreceipt/get',
	REPORT_DELETE = '/report/delete-month-csv-report',
	REPORT_GET_SINGLE_RECEIPT = 'report/singlereceipt/get',
	SINGLE_RECEIPTS_LIST_GET = '/billservice/single-receipt/list',
	SINGLE_RECEIPT_DETAILS_GET = '/billservice/single-receipt/get',

	OTHER_RECEIPTS_GET = '/report/other-receipts/list',
	OTHER_RECEIPTS_REPORT_GET = '/report/paymentreceipt/one',
	OTHER_RECEIPTS_BILL_CANCELED = '/portmone/bill/cancel',

	INIT_REDIS = '/etl-spd-mapping/init-cache',

	ETL_FILES_PARSING = '/direct-upload-spd',

	STOP_ETL_FILES_PARSING = '/direct-upload-provider-data/stop-parser',

	REJECTED_DATA_LIST_GET = '/utility/rejected-data/list',
	REJECTED_DATA_DISTINCT_CITIES_GET = '/utility/rejected-data/get-distinct-cities',
	REJECTED_DATA_DISTINCT_STREETS_GET = '/utility/rejected-data/get-distinct-streets',
  	REJECTED_DATA_DISTINCT_HOUSES_GET = '/utility/rejected-data/get-distinct-houses',
  	REJECTED_DATA_DISTINCT_FLATS_GET = '/utility/rejected-data/get-distinct-flats',

	PAYMENT_LIST_GET = '/payment-list',
	PAYMENT_BILL_INFO = '/billservice/bills/info',

	UNDEFINED_PROVIDER_DATA = '/utility/upd/map',
	STOP_UNDEFINED_PROVIDER_DATA_PARSING = '/utility/upd/stop-map',

	GENERATE_SINGLE_RECEIPT_ON_BILL = '/etl-spd-mapping/map-and-send-to-bill',
	STOP_GENERATE_SINGLE_RECEIPT_ON_BILL = '/utility/apartmentAccount/stop-send-bill',

	PUSH_NOTIFICATION_ALL = '/notifications/send-bulk',

	SINGLE_RECEIPT_STATUS = '/report/get-status-single-receipt ',
	UPLOAD_SINGLE_RECEIPT_STATUS = '/report/upload-single-receipt',
	GENERATE_REPORT_FOR_PDF = '/billservice/report/single-receipt',
	STOP_GENERATE_REPORT_FOR_PDF = '/billservice/report/single-receipt-stop',
	AUDIT_STATUSES_RESET = 'audit-processes/statuses/reset'
}
