import { createSlice } from '@reduxjs/toolkit';
import { IUserReducer } from 'types';
import { userLogout, userLogin, getUserProfile, updateUserProfile } from './actions';
import { StorageService } from 'utils/services';

const initialState: IUserReducer = {
	isLoading: true,
	data: null,
};

const userSlice = createSlice({
	name: 'userSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(userLogin.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(userLogin.fulfilled, (state, { payload }) => {
			state.data = payload.profile;
			state.isLoading = false;
		});

		builder.addCase(userLogin.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(userLogout.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(userLogout.fulfilled, (state) => {
			StorageService.clear();
			state.data = null;
			state.isLoading = false;
		});

		builder.addCase(userLogout.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(getUserProfile.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
			state.data = payload.profile;
			state.isLoading = false;
		});

		builder.addCase(getUserProfile.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.data = payload.profile;
		});

		builder.addCase(updateUserProfile.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(updateUserProfile.pending, (state) => {
			state.isLoading = true;
		});
	},
});

export const userReducer = userSlice.reducer;
