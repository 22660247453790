import { ALIASES_BY_PROVIDER_HEADER_TITLES } from 'utils/constants/streets';
import { ALIASES_BY_PROVIDER_FIELDS_NAME } from 'utils/enums';
import { Rights } from 'utils/enums/rights.enum';
import { CanIFn } from 'utils/hooks/use-permissions';
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import { IStreetsDetails } from "types";

export const COLUMNS_CONFIG: GridColDef<IStreetsDetails>[] = [
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.ID, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.ID, sortable: true, flex: 1 },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.CITY_NAME_CE, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.CITY_CE, sortable: false, flex: 1 },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.STREET_TYPE_NAME_CE, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.TYPE_STREET_CE, sortable: true, flex: 1 },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.NAME, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.STREET_CE, sortable: true, flex: 1 },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.STREET_ID, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.STREET_ID, sortable: false, editable: true, flex: 1 },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.PROVIDER, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.PROVIDER, sortable: true, flex: 1 },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.CITY_NAME, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.CITY, sortable: false, flex: 1, type: "custom" },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.STREET_TYPE_NAME, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.TYPE_STREET, sortable: false, flex: 1, type: "custom" },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.STREET, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.STREET, sortable: false, flex: 1, type: "custom" },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.CREATE_AT, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.CREATED_AT, sortable: true, flex: 1 },
	{ field: ALIASES_BY_PROVIDER_FIELDS_NAME.UPDATE_AT, headerName: ALIASES_BY_PROVIDER_HEADER_TITLES.UPDATED_AT, sortable: true, flex: 1 },
];

export const withPermissions = (canI: CanIFn) => {
	// COLUMNS_CONFIG.forEach(item => {
	// 	item.editable = item.right ? canI(item.right) : item.editable;
	// });
	return COLUMNS_CONFIG;
}
