import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearProvidersData, getProviders, getProvidersIsLoadingSelector, getProvidersSelector } from 'store/providers';
import { PAGINATION_LIMIT } from 'utils/constants';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { DATE_FORMAT, PROVIDERS_FIELDS_NAME, SORT_DIR } from 'utils/enums';
import { COLUMNS_CONFIG } from './config';
import { IQuery } from 'types';
import s from './index.module.scss';
import { formatDateView } from 'utils/helpers/date';
import { ROUTES } from "routes";
import { getTableSetting } from "utils/helpers/table-setting";
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";

export const ProvidersPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const providers = useAppSelector(getProvidersSelector);
	const isLoading = useAppSelector(getProvidersIsLoadingSelector);
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const requestBody = useMemo(
		() => ({
			pagination: {
				offset: (query.page || 0) * (query.pageSize || 0),
				limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			},
			sorting: {
				field: query.field || PROVIDERS_FIELDS_NAME.PROVIDER,
				direction: query.sort || SORT_DIR.ASC,
			},
			search: `${query.searchQuery || ''}`,
		}),
		[query]
	);

	useEffect(() => {
		dispatch(getProviders(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearProvidersData());
		};
	}, [dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.providers, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.providers));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const modifiedProvidersData = useMemo(
		() =>
			providers?.results.map((provider) => ({
				...provider,
				createdAt: formatDateView(provider.createdAt, DATE_FORMAT.DATE_FULL),
			})),
		[providers]
	);

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	return (
		<Box className={s.tableWrapper}>
			<DataGrid
				onColumnResize={handleColumnResize}
				apiRef={apiRef}
				disableColumnFilter
				disableRowSelectionOnClick
				rows={modifiedProvidersData || []}
				columns={COLUMNS_CONFIG}
				rowCount={providers?.total || 0}
				loading={isLoading}
				getRowId={(row: any) => row.provider}
				initialState={{
					pagination: {
						paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
					},
					sorting: {
						sortModel: [
							{ field: query.field || PROVIDERS_FIELDS_NAME.PROVIDER, sort: query.sort as GridSortDirection },
						],
					},
				}}
				slotProps={{
					pagination: {
						labelRowsPerPage: 'Показувати :',
						page: query.page || 0,
						rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
					},
				}}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortField}
				onPaginationModelChange={handleChangePage}
				className={s.table}
				pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
			/>
		</Box>
	);
};
