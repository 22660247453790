import { API } from 'api';
import { AxiosResponse } from 'axios';
import { ICreateRightRequest, ICreateRightResponse, IRightsResponse, IRolesAndRightsRequest } from 'types/roles-and-rights.interface';
import { API_URL } from 'utils/enums';

class RightsService {
	static getRights = async (reqBody: IRolesAndRightsRequest): Promise<AxiosResponse<IRightsResponse>> =>
		API.post(API_URL.USER_RIGHTS_LIST, reqBody);

	static createRight = async (reqBody: ICreateRightRequest): Promise<AxiosResponse<ICreateRightResponse>> =>
		API.post(API_URL.USER_RIGHTS_CREATE, reqBody);
}

export { RightsService };
