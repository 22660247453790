import { FC } from 'react';
import { FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface IOption {
	title: string;
	value: string;
}

export interface CustomSelectProps {
	options?: IOption[];
	value: string;
	placeholder: string;
	onChange: (value: SelectChangeEvent<string>) => void;
	name: string;
	className?: string;
	helperText?: string;
	disableOption?: boolean;
	multiple?: boolean;
}

export const CustomSelect: FC<CustomSelectProps> = ({
	value,
	options,
	className,
	placeholder,
	onChange,
	name,
	helperText,
	multiple
}) => {
	return (
		<div>
			<Select multiple={multiple} displayEmpty value={value ?? ''} className={className} onChange={onChange} name={name} >
				<MenuItem style={{ display: 'none' }} disabled value="">
					<em>{placeholder}</em>
				</MenuItem>
				{options?.map((item) => (
					<MenuItem key={item.value} value={item.value} title={item.title}>
						{item.title}
					</MenuItem>
				))}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</div>
	);
};
