import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, IconButton, TextField } from '@mui/material';
import {
	DataGrid,
	GridCellParams,
	GridPaginationModel,
	GridSortDirection,
	GridSortModel,
	useGridApiRef
} from '@mui/x-data-grid';
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { AxiosResponse } from 'axios';
import { ROUTES } from 'routes';
import { getAddressesIsLoadingSelector, getAllAddresses, getAllAddressesSelector } from 'store/addresses';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IQuery } from 'types';
import { PAGINATION_LIMIT } from 'utils/constants';
import { ADDRESSES_FIELDS_NAME, DATE_FORMAT } from 'utils/enums';
import { Rights } from 'utils/enums/rights.enum';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from "utils/helpers/table-setting";
import { usePermissions } from 'utils/hooks/use-permissions';
import { AddressesService } from 'utils/services';
import { COLUMNS_CONFIG } from './config';
import s from './index.module.scss';

export const AddressesPage: FC = () => {
	const addresses = useAppSelector(getAllAddressesSelector);
	const isLoading = useAppSelector(getAddressesIsLoadingSelector);
	const { search } = useLocation();
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const canI = usePermissions();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const [isAutocompleteLoading, setIsAutocompleteLoading] = useState({
		address: false,
		house: false,
		flat: false,
	});

	const [options, setOptions] = useState<{
		houses: string[];
		flats: string[];
	}>({
		houses: [],
		flats: [],
	});

	const trimFirstWordStreet = (str?: string): string | undefined => {
		if (!str) return undefined;
		const street = str.split(' ');
		street.shift();
		return street.join(' ');
	};
	const trimLastWordStreetType = (str?: string): string | undefined => {
		if (!str) return undefined;
		const streetType = str.split(' ');
		return streetType.shift();
	};

	const [searchQuery, setSearchQuery] = useState(query);

	const requestBody = useMemo(
		() => ({
			offset: (query.page || 0) * (query.pageSize || 0),
			limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			// orderBy: [{
			// 	field: query.field || ADDRESSES_FIELDS_NAME.STREET,
			// 	direction: query.sort || SORT_DIR.DSC,
			// }],
			searchBy: {
				city: "М. ХАРКІВ",
				streetName: trimFirstWordStreet(query.street),
				streetTypeShorName: trimLastWordStreetType(query.street),
				houseNumber: query.house && `${query.house}`,
				flatNumber: query.flat && `${query.flat}`,
			}
		}),
		[query]
	);

	useEffect(() => {
		setSearchQuery(query);
	}, [query]);

	useEffect(() => {
		dispatch(getAllAddresses(requestBody));
	}, [requestBody, dispatch]);

	const modifiedAddresses = useMemo(
		() =>
			addresses?.results.map((apartment) => ({
				...apartment,
				city: 'м. Харків',
				streetType: apartment.address.street.streetType.shorName,
				street: apartment.address.street.name,
				house: apartment.address.houseNumber,
				flat: apartment.flatNumber === null ? '' : apartment.flatNumber,
				createdAt: formatDateView(apartment.createdAt, DATE_FORMAT.DATE_FULL),
			})),
		[addresses]
	);

	useEffect(() => {
		if (query.street) {
			handleSearchHouse(query.street);
		}
	}, [query.street]);

	useEffect(() => {
		if (query.street && query.house) {
			handleSearchFlats(query.street, query.house);
		}
	}, [query.house, query.street]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.addresses, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.addresses));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const handleSearchHouse = async (value: string): Promise<void> => {
		try {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, house: true });
			const reqBody = {
				street: value,
				house: '',
			};
			const response: AxiosResponse<string[]> = await AddressesService.getHouses(reqBody);
			setOptions({ ...options, houses: response.data });
		} catch (error) {
			console.log(error);
		} finally {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, house: false });
		}
	};

	const handleSearchFlats = async (streetValue: string, houseValue: string): Promise<void> => {
		try {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, flat: true });
			const reqBody = {
				street: `${streetValue}`,
				house: `${houseValue}`,
			};
			const response: AxiosResponse<string[]> = await AddressesService.getFlats(reqBody);
			setOptions({ ...options, flats: response.data });
		} catch (error) {
			console.log(error);
		} finally {
			setIsAutocompleteLoading({ ...isAutocompleteLoading, flat: false });
		}
	};

	const handleAutocompleteChange = (fieldName: string, value: string | null): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					[fieldName]: value,
				}),
			},
			{ replace: true }
		);
	};

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const handleSearchClear = () => {
		navigate(
			{
				search: formatQueryString({
					query: '',
				}),
			},
			{ replace: true }
		);
	};

	const handleClickEdit = (params: GridCellParams): void => {
		navigate({
			pathname: `/${ROUTES.addresses}/${params.id}`,
			search: formatQueryString({
				...query,
			}),
		});
	};

	return (
		<>
			<Box className={s.tableActions}>
				<Box className={s.tableActions__items}>
					<Box className={s.tableActions__apartments}>
						<Autocomplete
							noOptionsText="Не знайдено"
							options={options.houses}
							renderInput={(params) => <TextField label="Будинок" {...params} />}
							getOptionLabel={(option: string) => option}
							disabled={!query.street}
							onChange={(_, value, reason) => handleAutocompleteChange('house', value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									padding: 0,
								},
							}}
							value={searchQuery.house || ''}
						/>
						<Autocomplete
							noOptionsText="Не знайдено"
							options={options.flats}
							renderInput={(params) => <TextField label="Квартира" {...params} />}
							getOptionLabel={(option: string) => option}
							disabled={!query.house}
							onChange={(_, value) => handleAutocompleteChange('flat', value)}
							sx={{
								'& .MuiOutlinedInput-root': {
									padding: 0,
								},
							}}
							value={searchQuery.flat || ''}
						/>
					</Box>
					<Button variant="contained" disabled={!(query.house || query.flat)} onClick={handleSearchClear}>
						Очистити
					</Button>
				</Box>
			</Box>
			<Box className={s.tableWrapper}>
				<DataGrid
					onColumnResize={handleColumnResize}
					apiRef={apiRef}
					disableColumnFilter
					disableRowSelectionOnClick
					rows={modifiedAddresses || []}
					rowSelection={false}
					loading={isLoading}
					columns={[
						...COLUMNS_CONFIG,

						{
							field: 'edit',
							headerName: ' ',
							sortable: false,
							align: 'center',
							width: 60,
							renderCell: (params) => (
								canI(Rights.APARTMENTS_MANAGE) &&
								<IconButton onClick={() => handleClickEdit(params)} color="primary" aria-label="Edit">
									<Edit />
								</IconButton>
							),
						},
					]}
					rowCount={addresses?.total || 0}
					initialState={{
						pagination: {
							paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
						},
						sorting: {
							sortModel: [
								{ field: query.field || ADDRESSES_FIELDS_NAME.STREET, sort: query.sort as GridSortDirection },
							],
						},
					}}
					slotProps={{
						pagination: {
							labelRowsPerPage: 'Показувати :',
							page: query.page || 0,
							rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
						},
					}}
					paginationMode="server"
					sortingMode="server"
					onSortModelChange={handleSortField}
					onPaginationModelChange={handleChangePage}
					className={s.table}
					pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
				/>
			</Box>
		</>
	);
};
