import { Avatar, Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './index.module.scss';

interface IDetailsProps {
	title?: string;
	firstName?: string;
	lastName?: string;
	image?: string;
}

export const DetailsHeader: FC<IDetailsProps> = ({ firstName, lastName, title, image }) => {
	return (
		<>
			<Box className={styles.wrapper}>
				<Box className={styles.wrapper__info}>
					<Avatar src={image || ''} alt={firstName} className={styles.avatar} />
					{title ? (
						<Typography variant="h5">{title}</Typography>
					) : (
						<Box>
							<Typography variant="h5">{firstName}</Typography>
							<Typography variant="h5">{lastName}</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};
