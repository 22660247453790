import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { DetailsContent } from "components/modules/DetailsContent";
import { DetailsHeader } from "components/modules/DetailsHeader";
import styles from './index.module.scss';

import { ReactComponent as TrashService } from 'assets/icons/trash.svg';
import Loading from "components/modules/Loading";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "routes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getRoleDetails, getRoleDetailsSelector, getRoleDetailsUpdateSuccess, updateRoleDetails } from "store/role";
import { ROLE_DETAILS_KEYS } from "utils/enums";
import { DETAILS_TITLES } from "./config";
import { IGetRoleDetailsResponse, IUpdateRoleDetailsRequest } from "types/roles-and-rights.interface";
import { getRights, isRightsFirstCallSelector } from "store/rights-list";

export const RolesDetailsPage = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [detailsData, setDetailsData] = useState<IUpdateRoleDetailsRequest | null>(null);
    const [updatedUserData, setUpdatedUserData] = useState<Partial<IUpdateRoleDetailsRequest>>({});

    const isRightsFirstCall = useAppSelector(isRightsFirstCallSelector);

    const roleDetails = useAppSelector(getRoleDetailsSelector);
    const detailsUpdateSuccess = useAppSelector(getRoleDetailsUpdateSuccess);

    const roleDetailsInitialValue: IUpdateRoleDetailsRequest = useMemo(() => {
        return {
            roleId: roleDetails?.id ?? '',
            name: roleDetails?.name ?? '',
            description: roleDetails?.description ?? '',
            rights: roleDetails?.rights ?? [],
            status: roleDetails?.status ?? '',
        }
    }, [roleDetails]);

    const handleUpdateUser = () => {
        if (detailsData) {
            dispatch(updateRoleDetails({ ...updatedUserData, roleId: detailsData.roleId }));
        }
    };

    const handleDeleteUser = () => {
        navigate(`/${ROUTES.rights}`);
    };

    const handleUpdateUserData = (data: Partial<IGetRoleDetailsResponse>) => {
        setUpdatedUserData({ ...updatedUserData, ...data });
    };

    useEffect(()=>{
        if(isRightsFirstCall) {
            dispatch(getRights({limit: 100, offset: 0}));
        }
    }, [isRightsFirstCall, dispatch]);

    useEffect(() => {
        if (roleDetailsInitialValue) {
            setDetailsData(roleDetailsInitialValue);
        }
    }, [roleDetailsInitialValue]);

    useEffect(() => {
        if (id) {
            dispatch(getRoleDetails({ roleId: id }));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (detailsUpdateSuccess && id) {
            dispatch(getRoleDetails({ roleId: id }));
        }
    }, [detailsUpdateSuccess, id, dispatch]);

    if (!detailsData) return <Loading />;

    return (<><Box className={styles.wrapper}>
        <Box className={styles.content}>
            <DetailsHeader
                firstName={roleDetailsInitialValue?.name || ''}
            />
            <Box className={styles.content}>
                <DetailsContent<ROLE_DETAILS_KEYS, IUpdateRoleDetailsRequest>
                    detailsTitles={DETAILS_TITLES}
                    title="Особисті дані"
                    detailsData={detailsData}
                    setDetailsData={setDetailsData}
                    onSetUpdatedData={handleUpdateUserData}
                />
            </Box>
            <Box className={styles.actions}>
                <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={handleUpdateUser}
                    disabled={!Object.values(updatedUserData).length}
                >
                    Оновити дані
                </Button>
                <Button variant="outlined" color="secondary" startIcon={<TrashService />} onClick={handleDeleteUser}>
                    Видалити обліковий запис
                </Button>
            </Box>
        </Box>
    </Box></>);
}