import { AsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RestState } from "./types";

type State = {
    item?: RestState;
    list?: RestState;
    create?: RestState;
    update?: RestState;
    delete?: RestState;
};

type Actions = {
    itemAction?: AsyncThunk<any, any, any>;
    listAction?: AsyncThunk<any, any, any>;
    createAction?: AsyncThunk<any, any, any>;
    updateAction?: AsyncThunk<any, any, any>;
    deleteAction?: AsyncThunk<any, any, any>;
}

export const createGenericSlice = (name: string, initialState: State, actions: Actions, reducers: {} = {}) => {
    return createSlice({
        name,
        initialState,
        reducers:{
            ...reducers
        },
        extraReducers(builder) {
            const { itemAction, listAction, createAction, updateAction, deleteAction } = actions;

            if (itemAction) {
                builder.addCase(itemAction.pending, (state) => {
                    state.item = {
                        data: null,
                        failure: false,
                        success: false,
                        pending: true,
                    }
                });

                builder.addCase(itemAction.fulfilled, (state, { payload }) => {
                    state.item = {
                        data: payload,
                        failure: false,
                        success: true,
                        pending: false,
                    }
                });

                builder.addCase(itemAction.rejected, (state) => {
                    state.item = {
                        data: null,
                        success: false,
                        failure: true,
                        pending: false
                    }
                });
            }

            if (listAction) {
                builder.addCase(listAction.pending, (state) => {
                    state.list = {
                        data: null,
                        failure: false,
                        success: false,
                        pending: true,
                    }
                });

                builder.addCase(listAction.fulfilled, (state, { payload }) => {
                    state.list = {
                        data: payload,
                        failure: false,
                        success: true,
                        pending: false,
                    }
                });

                builder.addCase(listAction.rejected, (state) => {
                    state.list = {
                        data: null,
                        success: false,
                        failure: true,
                        pending: false
                    }
                });
            }

            if (createAction) {
                builder.addCase(createAction.pending, (state) => {
                    state.create = {
                        data: null,
                        failure: false,
                        success: false,
                        pending: true,
                    }
                });

                builder.addCase(createAction.fulfilled, (state, { payload }) => {
                    state.create = {
                        data: payload,
                        failure: false,
                        success: true,
                        pending: false,
                    }
                });

                builder.addCase(createAction.rejected, (state) => {
                    state.create = {
                        data: null,
                        success: false,
                        failure: true,
                        pending: false
                    }
                });
            }

            if (updateAction) {
                builder.addCase(updateAction.pending, (state) => {
                    state.update = {
                        data: null,
                        failure: false,
                        success: false,
                        pending: true,
                    }
                });

                builder.addCase(updateAction.fulfilled, (state, { payload }) => {
                    state.update = {
                        data: payload,
                        failure: false,
                        success: true,
                        pending: false,
                    }
                });

                builder.addCase(updateAction.rejected, (state) => {
                    state.update = {
                        data: null,
                        success: false,
                        failure: true,
                        pending: false
                    }
                });
            }

            if (deleteAction) {
                builder.addCase(deleteAction.pending, (state) => {
                    state.delete = {
                        data: null,
                        failure: false,
                        success: false,
                        pending: true,
                    }
                });

                builder.addCase(deleteAction.fulfilled, (state, { payload }) => {
                    state.delete = {
                        data: payload,
                        failure: false,
                        success: true,
                        pending: false,
                    }
                });

                builder.addCase(deleteAction.rejected, (state) => {
                    state.delete = {
                        data: null,
                        success: false,
                        failure: true,
                        pending: false
                    }
                });
            }
        },
    })
}