import { Box, Tab, Tabs } from '@mui/material';
// import { ADDRESS_DETAILS_TABS } from 'constants/tabs';
import { FC, useState } from 'react';
import styles from './index.module.scss';
import { ITab } from 'types';
import { ADDRESS_DETAILS_TABS } from 'utils/constants/tabs';

export const AddressDetailsPage: FC = () => {
	const [activeTab, setActiveTab] = useState(ADDRESS_DETAILS_TABS[0]);

	const changeTab = (newTab: ITab) => {
		setActiveTab(newTab);
	};

	return (
		<>
			<Tabs className={styles.wrapper} value={activeTab.title} indicatorColor='transparent'>
				{ADDRESS_DETAILS_TABS.map((tab: ITab) => (
					<Tab
						key={tab.title}
						label={tab.title}
						onClick={() => changeTab(tab)}
						value={tab.title}
					/>
				))}
			</Tabs>
			<Box>{activeTab.content}</Box>
		</>
	);
};
