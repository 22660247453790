import { API } from 'api';
import { AxiosResponse } from 'axios';
import {
	IAliasesByProviderRequestCreate, IAliasesByProviderRequestUpdate,
	ICategoriesDetails,
	ICategoriesResponse
} from 'types';
import { API_URL } from 'utils/enums';
import { IAliasesByProviderRequestList } from "types/aliases-by-provider.inteface";

class AliasesByProviderService {
	static getAliasesByProvider = async (reqBody: IAliasesByProviderRequestList): Promise<AxiosResponse<ICategoriesResponse>> =>
		API.post(API_URL.ALIASES_BY_PROVIDER_LIST, reqBody);

	static createAliasesByProvider = async (reqBody: IAliasesByProviderRequestCreate): Promise<AxiosResponse<ICategoriesDetails>> =>
		API.post(API_URL.ALIASES_BY_PROVIDER_CREATE, reqBody);

	static updateAliasesByProvider = async (reqBody: IAliasesByProviderRequestUpdate): Promise<AxiosResponse<ICategoriesDetails>> =>
		API.post(API_URL.ALIASES_BY_PROVIDER_UPDATE, reqBody);
}

export { AliasesByProviderService };
