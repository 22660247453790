export enum ADDRESSES_FIELDS_NAME {
	ID = 'id',
	CITY = 'city',
	STREET_TYPE = 'streetType',
	STREET = 'street',
	HOUSE = 'house',
	FLAT = 'flat',
	CREATED_AT = 'createdAt',
}

export enum STREETS_FIELDS_NAME { //TODO check FIELDS
	ID = 'id',
	NAME = 'name',
	STREET_TYPE_SHOR_NAME = 'shorName',
	CITY_NAME = 'city',
	STREET_TYPE_NAME = 'streetType',
	CREATE_AT = 'createdAt',
	UPDATE_AT = 'updatedAt',
	NAME_STREET_TYPE = 'streetType.name',
	NAME_CITY = 'city.name'
}

export enum ALIASES_BY_PROVIDER_FIELDS_NAME {
	ID = 'id',
	NAME = 'name', // STREET_CE
	CITY_NAME_CE = 'cityCE',
	STREET_TYPE_NAME_CE = 'streetType.name',
	STREET_TYPE_NAME = 'streetType',
	STREET_ID = 'streetId',
	CITY_NAME = 'city',
	STREET = 'street',
	PROVIDER = 'provider.name',
	CREATE_AT = 'createdAt',
	UPDATE_AT = 'updatedAt',
}

export enum SPECIFY_DATA_FIELDS_NAME {
	STREET_TYPE = 'streetType',
	STREET = 'street',
	HOUSE = 'house',
	FLAT = 'flat',
	PROVIDER_TYPE = 'providerType',
	REASON = 'errorsMsg',
	PROVIDER_ACCOUNT_ID = 'providerAccountId',
}

export enum REJECTED_DATA_FIELDS_NAME {
	CITY = 'city',
	STREET_TYPE = 'streetType',
	STREET = 'street',
	HOUSE = 'house',
	FLAT = 'flat',
	SERVICES_CODE = 'serviceCode',
	SERVICES_NAME = 'serviceName',
	PROVIDER = 'provider',
	REASON = 'reasons',
	PROVIDER_ACCOUNT_ID = 'providerAccountId',
	CREATED_AT = 'createdAt',
}

export enum USERS_FIELDS_NAME {
	ID = 'id',
	FIRST_NAME = 'firstName',
	EMAIL = 'email',
	PHONE = 'phone',
	STATUS = 'status',
	ROLE = 'type',
	UPDATED_AT = 'updatedAt',
}

export enum CLIENTS_FIELDS_NAME {
	ID = 'id',
	NAME = 'name',
	EMAIL = 'email',
	UPDATED_AT = 'updatedAt',
}

export enum SERVICES_FIELDS_NAME {
	ID = 'id',
	NAME = 'name',
	STATUS = 'status',
	CREATED_AT = 'createdAt',
}

export enum PROVIDERS_FIELDS_NAME {
	PROVIDER = 'provider',
	STATUS = 'status',
	CREATED_AT = 'createdAt',
}

export enum CATEGORIES_FIELDS_NAME {
	ID = 'id',
	NAME = 'name',
	CREATED_AT = 'createdAt',
	SEQUENCE_NUMBER = 'sequenceNumber',
}

export enum SINGLE_RECEIPTS_FIELDS_NAME {
	ID = 'id',
	PAYMENT_TYPE = 'paymentType',
	APARTMENT_ACCOUNT_ID = 'apartmentAccountId',
	CITY = 'city',
	ADDRESS = 'address',
	STATUS = 'status',
	BALANCE = 'balance',
	CREATED_AT = 'createdAt',
}

export enum OTHER_RECEIPTS_FIELDS_NAME {
	PROVIDER_SERVICE = 'providerService',
	USER_ID = 'userId',
	AMOUNT = 'amount',
	FEE = 'fee',
	PAID_AMOUNT = 'paidAmount',
	STATUS = 'status',
	CARD = 'cardPanMasked',
	REQ_DATA = 'reqData',
	CREATED_AT = 'createdAt',
	PAID_AT = 'paidAt',
}

export enum PAYMENT_FIELDS_NAME {
	PAYMENT_ID = 'paymentId',
	BILL_ID = 'externalId',
	SUM_TRANSACTION = 'amount',
	FEE = 'fee',
	STATUS = 'status',
	PAN = 'cardPanMasked',
	PAYMENT_AT = 'paidAt',
	CREATED_AT = 'createdAt',
}
