import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { theme } from 'utils/theme';
import App from './App';
import { store } from './store';
import './styles/index.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<CssVarsProvider theme={theme}>
			<App />
		</CssVarsProvider>
	</Provider>
);
