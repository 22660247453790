import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { userReducer } from './user';
import { systemReducer } from './system';
import { addressesReducer } from './addresses';
import { streetsReducer } from './streets';
import { streetsTypesReducer } from './streets-types';
import { rejectedDataReducer} from './rejected-data';
import { usersReducer } from './users-list';
import { clientsReducer } from './clients';
import { servicesReducer } from './bill-services';
import { providersReducer } from './providers';
import { categoriesReducer } from './categories';
import { reportsReducer } from './reports';
import { dataSettingsReducer } from './data-settings';
import { singleReceiptsReducer } from './single-receipts';
import { otherReceiptsReducer } from './other-receipts';
import { rolesReducer } from './roles-list';
import { rightsReducer } from './rights-list';
import { roleDetailsReducer } from './role';
import { AliasesByProviderReducer } from "./aliases-by-provider";
import { paymentsReducer } from "./payment";

export const store = configureStore({
	reducer: {
		user: userReducer,
		users: usersReducer,
		clients: clientsReducer,
		categories: categoriesReducer,
		services: servicesReducer,
		providers: providersReducer,
		addresses: addressesReducer,
		aliasesByProvider: AliasesByProviderReducer,
		streets: streetsReducer,
		streetTypes: streetsTypesReducer,
		rejectedData: rejectedDataReducer,
		system: systemReducer,
		reports: reportsReducer,
		dataSettings: dataSettingsReducer,
		singleReceipts: singleReceiptsReducer,
		otherReceipts: otherReceiptsReducer,
		roles: rolesReducer,
		rights: rightsReducer,
		roleDetails: roleDetailsReducer,
		payments: paymentsReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
