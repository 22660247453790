import { FC, ReactElement, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from '.';
import { getUserProfile, getUserReducerSelector } from 'store/user';
import { IUserReducer } from 'types';
import { StorageService } from 'utils/services';
import { STORAGE_KEY } from 'utils/enums';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface ProtectedRouteProps {
	allowedRoles: string[];
	children?: ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ allowedRoles, children }) => {
	const [isHasAccess, setIsHasAccess] = useState(false);

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	// get user data from store
	const user: IUserReducer = useAppSelector(getUserReducerSelector);

	useEffect(() => {
		if (user.data === null && allowedRoles.length) {
			const userId = StorageService.get<string>(STORAGE_KEY.USER_ID);
			if(!userId) {
				navigate(`/${ROUTES.login}`);
				return;
			}
			dispatch(getUserProfile(userId));
			return;
		}

		if (allowedRoles.length) {
			// TODO: rework when BE will send role
			const role: string | null = StorageService.get<string>(STORAGE_KEY.ROLE) || 'ADMIN';
			const sessionId: string | null = StorageService.get<string>(STORAGE_KEY.SESSION_ID);

			if (role === null || !sessionId) {
				navigate(`/${ROUTES.login}`);
			} else if (allowedRoles.includes(role)) {
				setIsHasAccess(true);
			} else {
				navigate(ROUTES.notFound);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.data, allowedRoles]);

	// possible to be a loader
	if (!isHasAccess && user.isLoading) return null;
	// if we have access show children or outlet (for exotic page wrapped by that component, or subrouting)
	return isHasAccess ? children || <Outlet /> : null;
};

export default ProtectedRoute;
