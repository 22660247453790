import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	clearStreetsData,
	getStreets,
	getStreetsIsLoadingSelector,
	getStreetsSelector,
	updateStreets,
} from 'store/streets';
import {
	formatQueryString,
	parseQueryString
} from 'utils/helpers';
import { DATE_FORMAT, SORT_DIR, STREETS_FIELDS_NAME } from 'utils/enums';
import { PAGINATION_LIMIT } from 'utils/constants';
import { COLUMNS_CONFIG, withPermissions } from './config';
import { IStreetsDetails } from 'types';
import { IQuery } from 'types';
import s from './index.module.scss';
import { useColumns } from 'utils/hooks/use-selection-fields';
import { usePermissions } from 'utils/hooks/use-permissions';
import { formatDateView } from "utils/helpers/date";
import { ROUTES } from "routes";
import { getTableSetting } from "utils/helpers/table-setting";
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";

export const StreetsPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const checkRights = usePermissions();
	const streets = useAppSelector(getStreetsSelector);
	const isLoading = useAppSelector(getStreetsIsLoadingSelector);
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const requestBody = useMemo(
		() => ({
			offset: (query.page || 0) * (query.pageSize || 0),
			limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			orderBy: [
				{
					field: query.field || STREETS_FIELDS_NAME.NAME,
					direction: query.sort || SORT_DIR.ASC,
				},
			],
			searchBy: {
				name: query.searchQuery
			},
			deviceFP: "6037e65eefbdasdfadsfasdf3"
		}),
		[query]
	);

	useEffect(() => {
		dispatch(getStreets(requestBody));
	}, [requestBody, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearStreetsData());
		};
	}, [dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.streets, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.streets));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleCellEdit = (newRow: IStreetsDetails, _: IStreetsDetails): IStreetsDetails => {
		dispatch(updateStreets(newRow));
		return newRow;
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const modifiedStreets = useMemo(
		() =>
			streets?.results.map((street) => ({
				...street,
        'streetType.name': street.streetType.name,
				'city.name': street.city.name,
				createdAt: formatDateView(street.createdAt, DATE_FORMAT.DATE_FULL),
				updatedAt: formatDateView(street.createdAt, DATE_FORMAT.DATE_FULL),
			})),
		[streets]
	);

	return (
		<Box className={s.tableWrapper}>
			<DataGrid
				onColumnResize={handleColumnResize}
				apiRef={apiRef}
				disableColumnFilter
				disableRowSelectionOnClick
				rows={modifiedStreets || []}  // TODO: change type IStreetDetails
				columns={useColumns(withPermissions(checkRights))}
				rowCount={streets?.total || 0}
				loading={isLoading}
				initialState={{
					pagination: {
						paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
					},
					sorting: {
						sortModel: [{ field: query.field || STREETS_FIELDS_NAME.CREATE_AT, sort: query.sort as GridSortDirection }],
					},
				}}
				slotProps={{
					pagination: {
						labelRowsPerPage: 'Показувати :',
						page: query.page || 0,
						rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
					},
				}}
				processRowUpdate={handleCellEdit}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortField}
				onPaginationModelChange={handleChangePage}
				className={s.table}
				pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
			/>
		</Box>
	);
};
