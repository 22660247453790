import { STREETS_HEADER_TITLES } from 'utils/constants/streets';
import { STREETS_FIELDS_NAME } from 'utils/enums';
import { Rights } from 'utils/enums/rights.enum';
import { CanIFn } from 'utils/hooks/use-permissions';

export const COLUMNS_CONFIG = [
	{ field: STREETS_FIELDS_NAME.ID, headerName: STREETS_HEADER_TITLES.ID, sortable: true, flex: 1.5 },
	{ field: STREETS_FIELDS_NAME.NAME, headerName: STREETS_HEADER_TITLES.STREET, sortable: true, flex: 1 },
	{ field: STREETS_FIELDS_NAME.NAME_STREET_TYPE, headerName: STREETS_HEADER_TITLES.TYPE_STREET, sortable: true, flex: 1 },
	{ field: STREETS_FIELDS_NAME.NAME_CITY, headerName: STREETS_HEADER_TITLES.CITY, sortable: true, flex: 1 },
	{ field: STREETS_FIELDS_NAME.CREATE_AT, headerName: STREETS_HEADER_TITLES.CREATED_AT, sortable: true, flex: 1 },
	{ field: STREETS_FIELDS_NAME.UPDATE_AT, headerName: STREETS_HEADER_TITLES.UPDATED_AT, sortable: true, flex: 1 },
];

export const withPermissions = (canI: CanIFn) => {
	// COLUMNS_CONFIG.forEach(item => {
	// 	item.editable = item.right ? canI(item.right) : item.editable;
	// }); TODO: uncomment when it is clear what can be edited

	return COLUMNS_CONFIG;
}
