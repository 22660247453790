import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorResponse } from 'utils/helpers';
import { StorageService, UserAuthService } from 'utils/services';
import {
	IUserLogoutRequest,
	IUserLoginResponse,
	IUserLoginRequest,
	IUserLogoutResponse,
	IUpdateUserProfileRequest,
	IGetUserProfileResponse,
	PasswordRecoveryResponse,
	IPasswordRecoveryResetRequest,
	ISetPasswordRequest,
} from 'types';
import { UserProfileService } from 'utils/services/user-profile.service';
import { STORAGE_KEY } from 'utils/enums';

export const userLogin = createAsyncThunk(
	'userSlice/login',
	async (reqBody: IUserLoginRequest, { rejectWithValue }) => {
		try {
			const userResponse: AxiosResponse<IUserLoginResponse> = await UserAuthService.login(reqBody);
			StorageService.set(STORAGE_KEY.SESSION_ID, userResponse.data.sessionId);
			StorageService.set(STORAGE_KEY.USER_ID, userResponse.data.userId);
			const response: AxiosResponse<IGetUserProfileResponse> = await UserProfileService.getUserProfile(
				userResponse.data.userId
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const userLogout = createAsyncThunk(
	'userSlice/logout',
	async (reqBody: IUserLogoutRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IUserLogoutResponse> = await UserAuthService.logout(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const userPasswordRecovery = createAsyncThunk(
	'userSlice/passwordRecovery',
	async (email: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<PasswordRecoveryResponse> = await UserAuthService.passwordRecovery(email);
			return response.data;
		} catch (error) {
			const errorMessage = error.response?.data?.message || error.message;
			return rejectWithValue(getErrorResponse(errorMessage));
		}
	}
);

export const userPasswordRecoveryReset = createAsyncThunk(
	'userSlice/passwordRecoveryReset',
	async (reqBody: IPasswordRecoveryResetRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<PasswordRecoveryResponse> = await UserAuthService.recoveryReset(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const userSetPassword = createAsyncThunk(
	'userSlice/userSetPassword',
	async (reqBody: ISetPasswordRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<PasswordRecoveryResponse> = await UserAuthService.setPassword(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getUserProfile = createAsyncThunk(
	'userSlice/getUserProfile',
	async (userId: string | null, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IGetUserProfileResponse> = await UserProfileService.getUserProfile(userId);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateUserProfile = createAsyncThunk(
	'userSlice/updateUserProfile',
	async (reqBody: IUpdateUserProfileRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IGetUserProfileResponse> = await UserProfileService.updateUserProfile(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

// TODO:  endpoint for delete profile

// export const deleteUserProfile = createAsyncThunk(
// 	'userSlice/deleteUserProfile',
// 	async (userId: string, { rejectWithValue }) => {
// 		try {
// 			const resp = await UserProfileService.deleteUserProfile(userId);
// 			console.log('resp :', resp);
// 			return resp;
// 		} catch (error) {
// 			return rejectWithValue(getErrorResponse(error));
// 		}
// 	}
// );
