import { API } from 'api';
import { AxiosResponse } from 'axios';
import { API_URL } from 'utils/enums';
import { IRejectedDataRequest, IRejectedDataResponse } from "types/rejected-data.interface";

class RejectedDataService {
	static getRejectedDataList = async (reqBody: IRejectedDataRequest): Promise<AxiosResponse<IRejectedDataResponse>> =>
		API.post(API_URL.REJECTED_DATA_LIST_GET, reqBody);

	// TODO: this service
	static getRejectedDistinctCities = async (reqBody: any): Promise<AxiosResponse<string[]>> =>
		API.post(API_URL.REJECTED_DATA_DISTINCT_CITIES_GET, {...reqBody, limit: 50, deviceFP: '6037e65eefbdasdfadsfasdf3'});

	static getRejectedDataDistinctStreet = async (reqBody: any): Promise<AxiosResponse<string[]>> =>
		API.post(API_URL.REJECTED_DATA_DISTINCT_STREETS_GET, {...reqBody, limit: 50, deviceFP: '6037e65eefbdasdfadsfasdf3'} );

	static getRejectedDataDistinctHouse = async (reqBody: any): Promise<AxiosResponse<string[]>> =>
		API.post(API_URL.REJECTED_DATA_DISTINCT_HOUSES_GET, {...reqBody, limit: 50, deviceFP: '6037e65eefbdasdfadsfasdf3'});

  static getRejectedDataDistinctFlat = async (reqBody: any): Promise<AxiosResponse<string[]>> =>
		API.post(API_URL.REJECTED_DATA_DISTINCT_FLATS_GET, {...reqBody, limit: 50, deviceFP: '6037e65eefbdasdfadsfasdf3'});
}

export { RejectedDataService };
