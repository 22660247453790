export const OTHER_RECEIPTS_HEADER_TITLES = {
	BILL_ID: 'ID білу',
	PARTNER_ID: 'ID партнера',
	PROVIDER_SERVICE: 'Сервіс',
	USER_ID: 'ID клієнта',
	AMOUNT: 'Сума',
	FEE: 'Комісія',
	PAID_AMOUNT: 'Разом',
	STATUS: 'Статус оплати',
	CARD: 'Картка',
	REQ_DATA: 'Реквізити',
	CREATED_AT: 'Дата створення',
	PAID_AT: 'Оплата',
	UPDATED_AT: 'Дата оновлення',
};
