import { useState, useEffect, FC, PropsWithChildren } from 'react';
import SocketContext, {IAuditProcessesStatus} from './context';
import { Socket, io } from 'socket.io-client';

const URL = process.env.REACT_APP_ADMIN_SOCKET_API_URL;

export const DataSettingsSocketProvider: FC<PropsWithChildren> = (props) => {
	const [isConnected, setIsConnected] = useState(false);
	const [processesStatus, setProgressesStatusData] = useState<IAuditProcessesStatus>({});

	const handleProcessesStatus = (data: any) => {
		setProgressesStatusData( {...data});
	};

	const handleConnect = () => {
		setIsConnected(true);
	};

	const initSocket = () => {
		try {
			if (!URL) {
				throw new Error('URL is not defined');
			}
			const socket: Socket = io(URL);
			return socket;
		} catch (error) {
			console.log(' error:', error);
		}
	};

	useEffect(() => {
		const socket = initSocket();
		if (socket) {
			socket.on('connect', handleConnect);
			socket.on('processesStatus', handleProcessesStatus);
		}
		return () => {
			if (socket) {
				socket.off('connect', handleConnect);
				socket.off('processesStatus', handleProcessesStatus);
			}
		};
	}, []);

	return <SocketContext.Provider value={{ processesStatus, isConnected }}>{props.children}</SocketContext.Provider>;
};
