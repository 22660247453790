import { FC, useMemo } from 'react';
import s from './styles.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { PLATFORM_TABS } from 'utils/constants/tabs';
import { Button, Divider, Typography } from '@mui/material';
import SearchBar from '../SearchBar';
import UserProfile from '../UserProfile';
import { formatQueryString, generateNestedRoute, parseQueryString } from 'utils/helpers';
import { ROUTES } from 'routes';
import { CREATE_ROUTES } from 'utils/constants';
import { useAppDispatch } from 'store/hooks';
import { changeModalOpen } from 'store/system';
import { IQuery } from 'types';

const Header: FC = () => {
	const dispatch = useAppDispatch();
	const { pathname, search } = useLocation();
	const navigate = useNavigate();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

	const pageTitle = useMemo(() => PLATFORM_TABS.find((el) => '/' + el.path === pathname)?.title, [pathname]);

	const segments = pathname.split('/').filter(Boolean);

	const handlerGoBack = () => {
		navigate({
			pathname: generateNestedRoute(...segments.slice(0, -1)),
			search: formatQueryString({
				...query,
			}),
		});
	};

	const handleOpenCreateModal = () => {
		dispatch(changeModalOpen());
	};

	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				{pathname !== `/${ROUTES.profileSetups}` ? (
					segments.length > 1 ? (
						<Button variant="text" color="primary" onClick={handlerGoBack}>{`< Назад`}</Button>
					) : (
						<>
							<Typography variant="h4">{pageTitle}</Typography>
							{!(pathname === `/${ROUTES.reportData}` || pathname === `/${ROUTES.dataSettings}`) && <SearchBar />}
							{CREATE_ROUTES.includes(pathname) && (
								<>
									<Divider orientation="vertical" flexItem />
									<Button variant="outlined" onClick={handleOpenCreateModal}>
										+ Додати
									</Button>
								</>
							)}
						</>
					)
				) : (
					<Typography variant="h4">Мій профіль</Typography>
				)}
			</div>
			<UserProfile />
		</div>
	);
};

export default Header;
