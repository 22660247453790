import { Autocomplete, AutocompleteChangeReason, CircularProgress, SxProps, TextField, debounce } from '@mui/material';
import { API } from 'api';
import { SyntheticEvent, useState } from 'react';
import { SORT_DIR, STREETS_FIELDS_NAME } from 'utils/enums';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface AsyncAutocompleteProps {
	searchEndpoint: string;
	setValue: (value: string) => void;
	setAdditionalData?: (value: unknown) => void;
	value: string;
	sx?: SxProps;
}

export const AsyncAutocomplete = <T extends { value: string; trueValue: string }>({
	searchEndpoint,
	setValue,
	value,
	setAdditionalData,
	sx,
}: AsyncAutocompleteProps) => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<Array<T>>([]);

	const [inputValue, setInputValue] = useState(value);
	const loading = open && options?.length === 0;

	const getSuggestions = async (search: string) => {
		try {
			const { data } = await API.post(searchEndpoint, {
				pagination: {
					offset: 0,
					limit: 5,
				},
				sorting: {
					field: STREETS_FIELDS_NAME.CREATE_AT,
					direction: SORT_DIR.ASC,
				},
				search,
			});
			setOptions(data?.results);
		} catch (error) {
			console.log('error :', error);
		}
	};

	const handleSearch = debounce((search: string) => getSuggestions(search), 400);

	const handleSelectOption = (_: SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason) => {
		if (reason === 'clear') {
			setValue('');
			setOpen(false);
		}
		if (value) {
			setValue(value.trueValue);
			setAdditionalData?.(value);
			setOpen(false);
		}
	};

	return (
		<Autocomplete
			freeSolo
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			onChange={handleSelectOption}
			getOptionLabel={(option: any) => option.trueValue}
			options={options}
			onInputChange={(_, newValue) => {
				handleSearch(newValue);
				setInputValue(newValue);
			}}
			inputValue={inputValue}
			sx={sx}
			clearIcon={loading ? <CircularProgress color="inherit" size={20} /> : <CloseIcon />}
			renderInput={(params) => <TextField {...params} label="Пошук" />}
		/>
	);
};
