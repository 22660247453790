import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { getApartmentTitle } from 'utils/helpers/address';
import { Button, Divider, Typography } from '@mui/material';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { useModal } from 'utils/hooks';
import { EditModal } from 'components/elements';
import { IAddressDetailsItem, IAddressDetailsSetItem } from 'types';
import { IApartmentAccountUpdate } from 'types/apartment-account.interface';
import { IUseModal } from 'types/modal';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAddressById, getAddressDetailsSelector, updateAddressById } from 'store/addresses';
import { ADDRESS_DETAILS_KEYS, API_URL } from 'utils/enums';
import styles from './index.module.scss';

export const BasisTabPage: FC = () => {
	const [addressDetails, setAddressDetails] = useState<IApartmentAccountUpdate | null>(null);
	const [selectedItem, setSelectedItem] = useState<IAddressDetailsSetItem | null>(null);
	const addressDetailsInitialValue = useAppSelector(getAddressDetailsSelector);

	const [isOpen, handleOpen, handleClose]: IUseModal = useModal();

	const { id } = useParams();

	const dispatch = useAppDispatch();

	const separateAddressDetailsTitles: IAddressDetailsItem[] = [
		{ title: 'HOST ID', key: ADDRESS_DETAILS_KEYS.ID, modalTitle: '' },
		{ title: 'Місто', key: ADDRESS_DETAILS_KEYS.CITY, modalTitle: 'Вкажіть назву міста' },
		{
			title: 'Тип вулиці',
			key: ADDRESS_DETAILS_KEYS.STREET_TYPE,
			modalTitle: 'Вкажіть тип вулиці',
			searchEndpoint: API_URL.STREETS_TYPES_GET,
		},
		{
			title: 'Вулиця',
			key: ADDRESS_DETAILS_KEYS.STREET,
			modalTitle: 'Вкажіть назву вулиці',
			searchEndpoint: API_URL.STREETS_GET,
		},
		{
			title: 'Будинок',
			key: ADDRESS_DETAILS_KEYS.HOUSE,
			modalTitle: 'Вкажіть номер будинку',
		},
		{
			title: 'Квартира',
			key: ADDRESS_DETAILS_KEYS.FLAT,
			modalTitle: 'Вкажіть номер квартири',
		},
	];

	const handleSelect = (item: IAddressDetailsSetItem) => {
		setSelectedItem(item);
		handleOpen();
	};

	const handleModalClose = () => {
		handleClose();
		setSelectedItem(null);
	};

	const handleSave = (value: string, additionalData?: any) => {
		if (selectedItem) {
			setAddressDetails(
				(prev) =>
					prev && {
						...prev,
						[selectedItem.key]: value,
					}
			);
			if (additionalData) {
				setAddressDetails(
					(prev) =>
						prev && {
							...prev,
							[`${selectedItem.key}Id`]: additionalData.id,
						}
				);
			}
			handleModalClose();
		}
	};

	const handleUpdateApartment = () => {
		if (addressDetails && addressDetailsInitialValue) {
			const reqBody = {
				...addressDetails,
				street: addressDetails.street !== addressDetailsInitialValue.street ? addressDetails.streetId : undefined,
				streetType:
					addressDetails.streetType !== addressDetailsInitialValue.streetType ? addressDetails.streetTypeId : undefined,
			};
			dispatch(updateAddressById(reqBody));
		}
	};

	useEffect(() => {
		if (addressDetailsInitialValue) {
			setAddressDetails({ ...addressDetailsInitialValue });
		}
	}, [addressDetailsInitialValue]);

	useEffect(() => {
		if (id) {
			dispatch(getAddressById(id));
		}
	}, [id, dispatch]);

	return (
		<>
			{addressDetails && (
				<Box className={styles.wrapper}>
					<Box className={styles.address__info}>
						<Typography variant="h6">{getApartmentTitle(addressDetails)}</Typography>
					</Box>
					<Box className={styles.address__details}>
						<Typography variant="h6" className={styles.address__details_header}>
							Дані
						</Typography>
						<Box className={styles.address__details_content}>
							{separateAddressDetailsTitles.map((item, index) => (
								<Box key={item.key}>
									<Box
										className={styles.address__details_item}
										onClick={() =>
											item.key !== ADDRESS_DETAILS_KEYS.CITY &&
											item.key !== ADDRESS_DETAILS_KEYS.ID &&
											handleSelect({ value: addressDetails[item.key] || '', ...item })
										}
									>
										<Typography variant="h6">{item.title}</Typography>
										<Typography variant="h5">{addressDetails[item.key]}</Typography>
									</Box>
									<Box className={styles.address__details_divider}>
										{separateAddressDetailsTitles.length - 1 !== index && <Divider />}
									</Box>
								</Box>
							))}
						</Box>
					</Box>
					<Box className={styles.actions}>
						<Button
							variant="outlined"
							startIcon={<RefreshIcon />}
							onClick={handleUpdateApartment}
							className={styles.actions_update}
						>
							Оновити дані
						</Button>
					</Box>
					{selectedItem && (
						<EditModal
							isOpen={isOpen}
							onClose={handleModalClose}
							title={selectedItem?.modalTitle}
							selectedItem={selectedItem}
							handleSave={handleSave}
							defaultValue={selectedItem.value}
						/>
					)}
				</Box>
			)}
		</>
	);
};
