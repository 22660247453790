import { TextField } from "@mui/material"
import { useFormikContext } from "formik";
import { ICreateRightRequest } from "types/roles-and-rights.interface";

export const AddRight = () => {
	const { getFieldProps, errors, touched } = useFormikContext<ICreateRightRequest>();

    return (<>
        <TextField
            label="Назва"
            {...getFieldProps('name')}
            helperText={errors.name && touched.name ? errors.name : ''}
        />
        <TextField
            variant="outlined"
            label="Опис"
            {...getFieldProps('description')}
            helperText={errors.description && touched.description ? errors.description : ''}
        />
    </>)
}