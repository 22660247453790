import { USERS_HEADER_TITLES, USER_STATUS_TITLE } from 'utils/constants/users';
import { USERS_FIELDS_NAME } from 'utils/enums';

export const COLUMNS_CONFIG = [
	{ field: USERS_FIELDS_NAME.ID, headerName: USERS_HEADER_TITLES.ID, flex: 1 },
	{ field: USERS_FIELDS_NAME.FIRST_NAME, headerName: USERS_HEADER_TITLES.NAME, flex: 0.7 },
	{ field: USERS_FIELDS_NAME.EMAIL, headerName: USERS_HEADER_TITLES.EMAIL, flex: 0.7 },
	{ field: USERS_FIELDS_NAME.PHONE, headerName: USERS_HEADER_TITLES.PHONE, flex: 0.6 },
	{ field: USERS_FIELDS_NAME.STATUS, headerName: USERS_HEADER_TITLES.STATUS, flex: 0.4 },
	{ field: USERS_FIELDS_NAME.ROLE, headerName: USERS_HEADER_TITLES.ROLE, flex: 0.5 },
	{ field: USERS_FIELDS_NAME.UPDATED_AT, headerName: USERS_HEADER_TITLES.UPDATED_AT, flex: 0.6 },
];

export const getCellClassName = (params: any) => {
	if (params.field === USERS_FIELDS_NAME.STATUS && params.value !== null) {
		return params.value === USER_STATUS_TITLE.DELETED ? 'negative' : 'positive';
	} else {
		return '';
	}
};
