import { API } from 'api';
import { AxiosResponse } from 'axios';
import { ICreateRoleRequest, ICreateRoleResponse, IGetRoleDetailsRequest, IGetRoleDetailsResponse, IRolesAndRightsRequest, IRolesResponse, IUpdateRoleDetailsRequest, IUpdateRoleDetailsResponse } from 'types/roles-and-rights.interface';
import { API_URL } from 'utils/enums';

class RolesService {
	static getRoles = async (reqBody: IRolesAndRightsRequest): Promise<AxiosResponse<IRolesResponse>> =>
		API.post(API_URL.USER_ROLES_LIST, reqBody);

	static updateRoles = async (reqBody: IUpdateRoleDetailsRequest): Promise<AxiosResponse<IUpdateRoleDetailsResponse>> =>
		API.patch(API_URL.USER_ROLES_UPDATE, reqBody);

	static createRole = async (reqBody: ICreateRoleRequest): Promise<AxiosResponse<ICreateRoleResponse>> =>
		API.post(API_URL.USER_ROLES_CREATE, reqBody);

	static getRole = async (reqBody: IGetRoleDetailsRequest): Promise<AxiosResponse<IGetRoleDetailsResponse>> =>
		API.post(API_URL.USER_GET_ROLE, reqBody);

}

export { RolesService };
