import { createSlice } from '@reduxjs/toolkit';
import { IReportsReducer } from 'types';
import { deleteReportById, generateReports, getReportsList, getReportListById } from './actions';

const initialState: IReportsReducer = {
	reportListById: [],
	reportsList: [],
	isLoading: false,
};
const reportsSlice = createSlice({
	name: 'reportsSlice',
	initialState,
	reducers: {
		resetReportsList: (state) => {
			state.reportListById = [];
			state.reportsList = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(generateReports.pending, (state) => {
				state.isLoading = true;
			})

			.addCase(generateReports.fulfilled, (state) => {
				state.isLoading = false;
			})

			.addCase(generateReports.rejected, (state) => {
				state.isLoading = false;
			})

			.addCase(getReportsList.pending, (state) => {
				state.isLoading = true;
			})

			.addCase(getReportsList.fulfilled, (state, { payload }) => {
				state.reportsList = [...state.reportsList, ...payload];
				state.isLoading = false;
			})

			.addCase(getReportsList.rejected, (state) => {
				state.isLoading = false;
			})

			.addCase(deleteReportById.fulfilled, (state, { payload }) => {
				state.reportsList = state.reportsList.filter((el) => el.id !== payload);
				state.isLoading = false;
			})

			.addCase(getReportListById.rejected, (state) => {
				state.isLoading = false;
			})

			.addCase(getReportListById.pending, (state) => {
        state.isLoading = true;
      })

			.addCase(getReportListById.fulfilled, (state, {payload}) => {
				state.reportListById = payload;
				state.isLoading = false;
			});
	},
});
export const { resetReportsList } = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;
