import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ICreateRightRequest, ICreateRightResponse, IRightsResponse, IRolesAndRightsRequest } from "types/roles-and-rights.interface";
import { getErrorResponse } from "utils/helpers";
import { RightsService } from "utils/services/rights.service";

export const getRights = createAsyncThunk('rightsSlice/getRights', async (input: IRolesAndRightsRequest, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<IRightsResponse> = await RightsService.getRights(input)
        return response.data;
    } catch (error) {
        return rejectWithValue(getErrorResponse(error));
    }
});

export const createRight = createAsyncThunk('rightsSlice/createRight', async (request: ICreateRightRequest, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<ICreateRightResponse> = await RightsService.createRight(request);
        return response.data;
    } catch (error) {
        return rejectWithValue(getErrorResponse(error));
    }
});