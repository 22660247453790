import { Box } from '@mui/material';
import Header from 'components/modules/Header';
import Sidebar from 'components/modules/Sidebar';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import s from './styles.module.scss';

const MainLayout: FC = () => {
	return (
		<Box className={s.wrapper}>
			<Sidebar />
			<main className={s.main}>
				<Header />
				<div className={s.pages}>
					<Outlet />
				</div>
			</main>
		</Box>
	);
};

export default MainLayout;
