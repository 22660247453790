import { Box } from "@mui/system";
import { DataGrid, GridPaginationModel, GridSortModel, useGridApiRef } from "@mui/x-data-grid";
import { CreateModal } from "components/elements";
import { AddRight } from "components/modules/AddRight";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { createRight, getRights, getRightsIsLoadingSelector, getRightsSelector } from "store/rights-list";
import { IQuery } from "types";
import { PAGINATION_LIMIT } from "utils/constants";
import { formatQueryString, parseQueryString } from "utils/helpers";
import * as yup from 'yup';
import s from './index.module.scss';
import { ICreateRoleRequest } from "types/roles-and-rights.interface";
import { getTableSetting } from "utils/helpers/table-setting";
import { ROUTES } from "routes";
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";

export const schema = yup.object().shape({
    test: yup.string()
});

const COLUMN_CONFIG = [

    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: "Description", flex: 1 },
    { field: 'status', headerName: "status", flex: 1 },

];

export const RightsPage = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const roles = useAppSelector(getRightsSelector);
    const isLoading = useAppSelector(getRightsIsLoadingSelector);
    const [columnWidths, setColumnWidths] = useState({});
    const apiRef = useGridApiRef();

    const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);

    const requestBody = useMemo(
        () => ({
            pagination: {
                offset: (query.page || 0) * (query.pageSize || 0),
                limit: (query.pageSize || PAGINATION_LIMIT.SMALL),
            }
        }), [query]);

    useEffect(() => {
        dispatch(getRights({
            ...requestBody.pagination
        }));
    }, [requestBody, dispatch]);

    const handleColumnResize = (params: any) => {
        const newWidths = {
            ...columnWidths,
            [params.colDef.field]: params.width,
        };
        setColumnWidths(newWidths);
        localStorage.setItem(ROUTES.rights, JSON.stringify(newWidths));
    };

    useEffect(() => {
        setColumnWidths(getTableSetting(ROUTES.rights));
    }, [requestBody]);

    COLUMN_CONFIG.forEach((item: GridColDef) => {
        // @ts-ignore
        if (columnWidths && columnWidths[item.field]) {
            // @ts-ignore
            item.width = columnWidths[item.field];
            item.flex = undefined;
        }
    });

    const submitCreateRight = (data: ICreateRoleRequest) => {
        dispatch(createRight(data));
        dispatch(getRights({ ...requestBody.pagination }));
    }

    const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
        navigate(
            {
                search: formatQueryString({
                    ...query,
                    page,
                    pageSize,
                }),
            },
            { replace: true }
        );
    };

    const handleSortField = (model: GridSortModel): void => {
        const { field, sort } = model[0] || {};
        navigate(
            {
                search: formatQueryString({
                    ...query,
                    field,
                    sort,
                    page: 0,
                }),
            },
            { replace: true }
        );
    };


    return (<Box className={s.tableWrapper}>
        <DataGrid
            apiRef={apiRef}
            columns={COLUMN_CONFIG}
            getRowId={(row: any) => row.name}
            disableColumnFilter
            onColumnResize={handleColumnResize}
            rows={roles?.tableData ?? []}
            loading={isLoading}
            onSortModelChange={handleSortField}
            onPaginationModelChange={handleChangePage}
        />
        <CreateModal<ICreateRoleRequest>
            title="Нове право"
            onSubmit={submitCreateRight}
            initialValues={{}}
            subTitle="Вкажіть додаткову інформацію"
            validationSchema={schema}
        ><AddRight /></CreateModal>
    </Box>)
}
