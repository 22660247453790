import {ROUTES} from "routes";
// import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";

//TODO: todo this
// export const setTableSetting = (tableConfig: GridColDef[]) => {
//     tableConfig.forEach((item: GridColDef) => {
//         // @ts-ignore
//         if (columnWidths[item.field]) {
//             // @ts-ignore
//             item.width = columnWidths[item.field];
//         }
//     });
// }

export const getTableSetting = (tableRoute: ROUTES) => {
    const savedWidths = localStorage.getItem(tableRoute);
    if(savedWidths)
    return JSON.parse(savedWidths)
}
