import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {IOtherReceiptsRequest, IOtherReceiptsResponse, IReportsDataList} from 'types';
import { getErrorResponse } from 'utils/helpers';
import { OtherReceiptsService } from 'utils/services';

export const getOtherReceiptsList = createAsyncThunk(
	'otherReceiptsSlice/getOtherReceiptsList',
	async (reqBody: IOtherReceiptsRequest, { rejectWithValue }) => {
		try {
			const otherReceiptsResponse: AxiosResponse<IOtherReceiptsResponse> =
				await OtherReceiptsService.getOtherReceiptsList(reqBody);
			return otherReceiptsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getOtherReceiptsReport = createAsyncThunk(
	'otherReceiptsSlice/getOtherReceiptsReport',
	async (billId: string, { rejectWithValue }) => {
		try {
			const otherReceiptsResponse: AxiosResponse<IReportsDataList> =
				await OtherReceiptsService.getOtherReceiptsReport(billId);
			return otherReceiptsResponse.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getOtherReceiptsBillCancel = createAsyncThunk(
	'otherReceiptsSlice/getOtherReceiptsBillCancel',
    async (billId: string, { rejectWithValue }) => {
        try {
            await OtherReceiptsService.getOtherReceiptsBillCancel(billId);
        } catch (error) {
            return rejectWithValue(getErrorResponse(error));
        }
    }
)
