import React, { FC, ReactElement } from "react";
import { Box } from "@mui/material";

interface ICustomCell {
	reqData: Record<string, string>;
}

const CustomCell: FC<ICustomCell> = ({ reqData }): ReactElement | null   => {
	if (reqData === null) return null;
	const renderKeyValuePairs = () => {
		return Object.entries(reqData).map(([key, value]) => (
			<Box key={key}>
				<Box component="span">{key}:</Box> <Box component="span">{value}</Box>
			</Box>
		));
	};

	return (
		<Box>{renderKeyValuePairs()}</Box>
	);
};

export const MemoizedCustomCell = React.memo(CustomCell);
