import { PAYMENTS_HEADER_TITLES } from "utils/constants";
import { PAYMENT_FIELDS_NAME } from "utils/enums";

export const COLUMNS_CONFIG = [
	{ field: PAYMENT_FIELDS_NAME.PAYMENT_ID, headerName: PAYMENTS_HEADER_TITLES.PAYMENT_ID, flex: 1 },
	{ field: PAYMENT_FIELDS_NAME.BILL_ID, headerName: PAYMENTS_HEADER_TITLES.BILL_ID, flex: 1 },
	{ field: PAYMENT_FIELDS_NAME.SUM_TRANSACTION, headerName: PAYMENTS_HEADER_TITLES.SUM_TRANSACTION, flex: 0.4 },
	{ field: PAYMENT_FIELDS_NAME.FEE, headerName: PAYMENTS_HEADER_TITLES.FEE, flex: 0.3 },
	{ field: PAYMENT_FIELDS_NAME.STATUS, headerName: PAYMENTS_HEADER_TITLES.STATUS, flex: 0.5 },
	{ field: PAYMENT_FIELDS_NAME.PAN, headerName: PAYMENTS_HEADER_TITLES.PAN, flex: 0.4 },
	{ field: PAYMENT_FIELDS_NAME.PAYMENT_AT, headerName: PAYMENTS_HEADER_TITLES.PAYMENT_AT, flex: 0.5 },
	{ field: PAYMENT_FIELDS_NAME.CREATED_AT, headerName: PAYMENTS_HEADER_TITLES.CREATED_AT, flex: 0.5 },
];

export const downloadCSV = (data: string, filename: string) => {
	const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	URL.revokeObjectURL(url);
};
