import {Box, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ReactComponent as RefreshIcon} from 'assets/icons/refresh.svg';
import {ReactComponent as TrashService} from 'assets/icons/trash.svg';
import {ReactComponent as TrashPrimaryService} from 'assets/icons/trash-primary.svg';
import {DetailsContent} from 'components/modules/DetailsContent';
import {DetailsHeader} from 'components/modules/DetailsHeader';
import Loading from 'components/modules/Loading';
import {FC, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {ROUTES} from 'routes';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getRoles, isRolesFirstCallSelector} from 'store/roles-list';
import {
	deleteUserById,
	getApartment,
	getCardList,
	getUserById,
	getUserCardListSelector,
	getUserDetailsApartmentSelector,
	getUserDetailsSelector,
	updateUserById,
	deleteCardById,
	updateUserStatusBlock,
	updateUserStatusBlockTemporary,
} from 'store/users-list';
import {USER_LOCKED_FIELDS} from 'utils/constants';
import {USER_DETAILS_KEYS, USER_STATUS} from 'utils/enums';
import {DETAILS_TITLES} from './config';
import {IApartments, IGetUserDetailsResponse, IUserCard, IUserEditOptions} from 'types';
import ConfirmationModal from "components/modules/ConfirmationModal";
import styles from './index.module.scss';

interface IModalOptions {
	title: string;
	message: string;
	typeCard: boolean;
	typeUser: boolean;
	cardId: string;
}

export const UsersDetailsPage: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {id} = useParams();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const initStateModal: IModalOptions = {
		title: "",
		message: "",
		typeCard: false,
		typeUser: false,
		cardId: "",
	}

	const [detailsData, setDetailsData] = useState<IGetUserDetailsResponse | null>(null);
	const [updatedUserData, setUpdatedUserData] = useState<IUserEditOptions>({});
	const [modalOptions, setModalOptions] = useState<IModalOptions>(initStateModal);

	const userDetailsInitialValue = useAppSelector(getUserDetailsSelector);
	const userDetailsApartment = useAppSelector(getUserDetailsApartmentSelector);
	const userCardList = useAppSelector(getUserCardListSelector);

	const isRolesFirstCall = useAppSelector(isRolesFirstCallSelector);

	const StyledLink = styled(RouterLink)(({theme}) => ({
		textDecoration: 'none',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: '4px',
		padding: '10px'
	}));

	const handleUpdateUser = () => {
		if (!detailsData || !updatedUserData) return;
		const reqBody = {
			userId: detailsData.id,
			type: detailsData.type
		};

		switch (updatedUserData.status) {
			case (USER_STATUS.BLOCKED): {
				dispatch(updateUserStatusBlock(reqBody));
				break;
			}
			case (USER_STATUS.TEMPORARY_BLOCKED): {
				dispatch(updateUserStatusBlockTemporary(reqBody));
				break;
			}
		}

		dispatch(updateUserById({...updatedUserData, userId: detailsData.id}));
		setUpdatedUserData({});
	};

	const handleDeleteUser = () => {
		if (!id) return;

		const reqBody = {
			cardId: modalOptions?.cardId,
			userId: detailsData?.id || id,
			cardType: 'OWN'
		};

		if (detailsData?.id && detailsData.type && modalOptions.typeUser) {
			dispatch(deleteUserById({userId: detailsData.id, type: detailsData.type}));
			navigate(`/${ROUTES.users}`);
		} else {
			dispatch(deleteCardById(reqBody));
			setIsModalOpen(false);
		}
	};

	const handleUpdateUserData = (data: Partial<IGetUserDetailsResponse>) => {
		setUpdatedUserData({...updatedUserData, ...data});
	};

	useEffect(() => {
		if (isRolesFirstCall) {
			dispatch(getRoles({limit: 100, offset: 0}));
		}
	}, [isRolesFirstCall, dispatch]);

	useEffect(() => {
		if (userDetailsInitialValue) {
			setDetailsData(userDetailsInitialValue);
		}
	}, [userDetailsInitialValue]);

	useEffect(() => {
		if (id) {
			dispatch(getUserById(id));
			dispatch(getApartment(id));
		}
	}, [id, dispatch]);

	useEffect(() => {
		if (id) {
			dispatch(getCardList(id));
		}
	}, [userCardList?.length]);

	const handleSetModalOptions = (typeModal: 'user' | 'card', id: string) => {
		if (typeModal === 'user') {
			setModalOptions({
				...modalOptions,
				typeUser: true,
				typeCard: false,
				cardId: "",
				title: `Видалити користувача ${detailsData?.firstName} ${detailsData?.lastName}`,
				message: 'Ви впевнені, що хочете видалити користувача? Якщо ви видалили користувача, він вже не може бути відновлений',
			});
		} else {
			setModalOptions({
				...modalOptions,
				typeUser: false,
				typeCard: true,
				cardId: id,
				title: 'Видалити картку',
				message: 'Ви впевнені, що хочете видалити картку?',
			});
		}
		setIsModalOpen(true)
	}

	if (!detailsData) return <Loading/>;

	return (
		<Box className={styles.wrapper}>
			<Box className={styles.content}>
				<DetailsHeader
					firstName={userDetailsInitialValue?.firstName || ''}
					lastName={userDetailsInitialValue?.lastName || ''}
				/>
				<Box className={styles.content}>
					<DetailsContent<USER_DETAILS_KEYS, IGetUserDetailsResponse>
						detailsTitles={DETAILS_TITLES}
						title="Особисті дані"
						detailsData={detailsData}
						setDetailsData={setDetailsData}
						onSetUpdatedData={handleUpdateUserData}
						lockedFields={USER_LOCKED_FIELDS}
					/>
				</Box>
				<Box className={styles.actions}>
					<Button
						variant="outlined"
						startIcon={<RefreshIcon/>}
						onClick={handleUpdateUser}
						disabled={!Object.values(updatedUserData).length}
					>
						Оновити дані
					</Button>
					<Button variant="outlined" color="secondary" startIcon={<TrashService/>}
									onClick={() => handleSetModalOptions('user', detailsData?.id)}>
						Видалити обліковий запис
					</Button>
				</Box>
			</Box>
			<Box className={styles.list}>
				<StyledLink
					target="_blank"
					to={`/${ROUTES.otherReceipts}?field=paidAt&page=0&searchQuery=${detailsData.id}&sort=desc`}>
					Інші Платежі користувача
				</StyledLink>
				{userDetailsApartment && userDetailsApartment.map((apartmentItem: IApartments) =>
					<StyledLink
						target="_blank"
						to={`/${ROUTES.singleReceipts}?page=0&searchQuery=${apartmentItem.apartmentAccountId}`}
						key={apartmentItem.apartmentAccountId}>
						{apartmentItem.apartmentLocation.address} {apartmentItem.apartmentLocation.flat && `кв. ${apartmentItem.apartmentLocation!.flat}`}
					</StyledLink>)}
			</Box>
			<Box className={styles.list}>
				{userCardList && userCardList.map((cardItem: IUserCard) =>
					<Button key={cardItem.cardId} startIcon={<TrashPrimaryService/>} className={styles.list__card}
									variant="outlined" color="primary" onClick={() => handleSetModalOptions('card', cardItem.cardId)}>
						{cardItem.panMasked}
					</Button>)}
			</Box>
			<ConfirmationModal
				open={isModalOpen}
				title={modalOptions.title}
				message={modalOptions.message}
			>
				<Box style={{display: 'flex', gap: '10px'}}>
					<Button onClick={() => setIsModalOpen(false)} style={{borderRadius: '5px'}} variant="contained"
									color="primary">
						Відмінити
					</Button>
					<Button onClick={handleDeleteUser} style={{borderRadius: '5px'}} variant="contained" color="secondary">
						Видалити
					</Button>
				</Box>
			</ConfirmationModal>
		</Box>
	);
};
