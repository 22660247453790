import { API } from 'api';
import { AxiosResponse } from 'axios';
import { IStreetsDetails, IStreetsRequest, IStreetsResponse } from 'types';
import { API_URL } from 'utils/enums';

class StreetsService {
	static getStreets = async (reqBody: IStreetsRequest): Promise<AxiosResponse<IStreetsResponse>> =>
		API.post(API_URL.STREETS_GET, reqBody);

	static createStreets = async (reqBody: IStreetsRequest): Promise<AxiosResponse<IStreetsResponse>> =>
		API.post(API_URL.STREETS_CREATE, reqBody);

	static updateStreets = async (reqBody: IStreetsDetails): Promise<AxiosResponse<IStreetsResponse>> =>
		API.patch(API_URL.STREETS_UPDATE, reqBody);
}

export { StreetsService };
