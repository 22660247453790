import {FC, useEffect, useState} from 'react';
import {Box, Button} from '@mui/material';
import {useParams} from 'react-router-dom';
import {DetailsContent} from 'components/modules/DetailsContent';
import {DetailsHeader} from 'components/modules/DetailsHeader';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getServiceDetailSelector, updateServiceFee} from 'store/bill-services';
import {getServiceById, updateServiceById} from 'store/bill-services/actions';
import {ReactComponent as Save} from 'assets/icons/save.svg';
import {SERVICE_DETAILS_FEE_KEYS, SERVICE_DETAILS_KEYS, SERVICE_DETAILS_REG_KEYS} from 'utils/enums';
import {
    SERVICE_DETAILS_FEE_TITLES,
    SERVICE_DETAILS_REG_TITLES,
    SERVICE_DETAILS_SUM_TITLES,
    SERVICE_DETAILS_TITLES,
    getLockedFields
} from 'pages/ServiceDetailsPage/config';
import {
    IContractFee,
    IFeeObjectAllData,
    IGetServiceDetailsResponse,
    IRegDataObject,
    IUpdateServiceFeeRequest,
    IUpdateServiceRequest
} from 'types';
import Loading from 'components/modules/Loading';
import s from './index.module.scss';
import {SERVICES_LOCKED_FEE_FIELDS, SERVICES_LOCKED_REG_FIELDS} from "utils/constants";
import {flatten} from "flat";
import {removePrefix} from "utils/helpers/remove-prefix";
import { fixedAmount } from "utils/helpers";
import { usePermissions } from 'utils/hooks/use-permissions';


// TODO remove commented block when will be approve

export const ServiceDetailsPage: FC = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const canI = usePermissions();

    const [serviceDetailsData, setServiceDetailsData] = useState<null | IGetServiceDetailsResponse>(null);
    const [serviceDetailsRegData, setServiceDetailsRegData] = useState<IRegDataObject>();
    const [serviceDetailFeeInnerData, setServiceDetailFeeInnerData] = useState<any>(); // TODO CHANGE TYPE AND OPTIMIZE CODE
    const [serviceDetailFeeOuterData, setServiceDetailFeeOuterData] = useState<any>();
    const [serviceUpdatedData, setServiceUpdatedData] = useState({});
    const [serviceUpdatedFeeInnerData, setServiceUpdatedFeeInnerData] = useState<IFeeObjectAllData | null>(null);
    const [serviceUpdatedFeeOuterData, setServiceUpdatedFeeOuterData] = useState<IFeeObjectAllData | null>(null);

    const serviceDetails = useAppSelector(getServiceDetailSelector);

    useEffect(() => {
        if (id) {
            dispatch(getServiceById(id));
        }
    }, [id]);

    useEffect(() => {
        if (serviceDetails) {
            setServiceDetailsData(serviceDetails);
            setServiceDetailsRegData(serviceDetails.regDataObject);
            serviceDetails.servicesContractsMap[0].contractFee.map((feeItem: IContractFee) => {
                const feeAllData = removePrefix(removePrefix(flatten(feeItem), 'feeObject.'), 'fee.');
                feeAllData.feeMax = fixedAmount(feeAllData.feeMax)
                feeAllData.feeMin = fixedAmount(feeAllData.feeMin)
                feeAllData.fixedAmount = fixedAmount(feeAllData.fixedAmount)
                feeAllData.sumFrom = fixedAmount(feeAllData.sumFrom)
                feeAllData.sumTo = fixedAmount(feeAllData.sumTo)
                feeItem.feeDirectionType === 'INNER'
                    ? setServiceDetailFeeInnerData(feeAllData)
                    : setServiceDetailFeeOuterData(feeAllData)
            });

        }
    }, [serviceDetails]);

    const handleUpdateServiceData = (data: IUpdateServiceRequest) => {
        setServiceUpdatedData({...serviceUpdatedData, ...data});
    };
    const handleUpdateServiceFeeInnerData = (data: Partial<IFeeObjectAllData>) => {
        setServiceUpdatedFeeInnerData(prevState => ({
            ...(prevState || {}),
            ...data
        } as IFeeObjectAllData));
    };
    const handleUpdateServiceFeeOuterData = (data: Partial<IFeeObjectAllData>) => {
        setServiceUpdatedFeeOuterData(prevState => ({
            ...(prevState || {}),
            ...data
        } as IFeeObjectAllData));
    };

    const handleSubmitUpdateService = () => {
        dispatch(updateServiceById({serviceId: id, ...serviceUpdatedData}));
    };

    const handleSubmitUpdateFeeService = (TypeFee: string) => {
        let serviceFeeUpdatedData: IFeeObjectAllData | null = null;

        if (TypeFee === 'INNER') {
            serviceFeeUpdatedData = {...serviceDetailFeeInnerData, ...serviceUpdatedFeeInnerData};
            setServiceUpdatedFeeInnerData(null)
        } else {
            serviceFeeUpdatedData = {...serviceDetailFeeOuterData, ...serviceUpdatedFeeOuterData};
            setServiceUpdatedFeeOuterData(null)
        }
        if (serviceFeeUpdatedData) {
            const reqBody: IUpdateServiceFeeRequest = {
                serviceId: serviceFeeUpdatedData.serviceId,
                feeDirectionType: serviceFeeUpdatedData.feeDirectionType,
                feeObject: {
                    sumFrom: fixedAmount(serviceFeeUpdatedData.sumFrom),
                    sumTo: fixedAmount(serviceFeeUpdatedData.sumTo),
                    feeMin: fixedAmount(serviceFeeUpdatedData.feeMin),
                    feeMax: fixedAmount(serviceFeeUpdatedData.feeMax),
                    fee: {
                        fixedAmount: Number(fixedAmount(serviceFeeUpdatedData.fixedAmount)),
                        percent: serviceFeeUpdatedData.percent,
                    }
                },
                outerFeeForbidden: serviceFeeUpdatedData.outerFeeForbidden,
                contractFeeId: serviceFeeUpdatedData.id,
            };
            dispatch(updateServiceFee(reqBody));

        } else {
            console.error('Service fee data is not available');
        }
    };

    if (!serviceDetailsData) return <Loading/>;

    return (
        <Box className={s.wrapper}>
            <DetailsHeader title={serviceDetailsData?.name as string} image={serviceDetailsData?.imageUrl}/>
            <DetailsContent<SERVICE_DETAILS_KEYS, IGetServiceDetailsResponse>
                title="Дані"
                onSetUpdatedData={handleUpdateServiceData}
                setDetailsData={setServiceDetailsData}
                detailsData={serviceDetailsData}
                detailsTitles={SERVICE_DETAILS_TITLES}
                lockedFields={getLockedFields(SERVICE_DETAILS_TITLES, canI)}
            />
            <Box className={s.actions}>
                <Button
                    startIcon={<Save opacity={!Object.values(serviceUpdatedData).length ? 0.3 : 1}/>}
                    variant="outlined"
                    disabled={!Object.values(serviceUpdatedData).length}
                    onClick={handleSubmitUpdateService}
                >
                    Зберегти
                </Button>
            </Box>
            {serviceDetailsRegData &&
                <DetailsContent<SERVICE_DETAILS_REG_KEYS, IRegDataObject>
                    title="Реквізити"
                    onSetUpdatedData={handleUpdateServiceData}
                    setDetailsData={setServiceDetailsRegData}
                    detailsData={serviceDetailsRegData}
                    detailsTitles={SERVICE_DETAILS_REG_TITLES}
                    lockedFields={SERVICES_LOCKED_REG_FIELDS}
                />
            }
            {serviceDetailFeeInnerData &&
                <Box className={s.fee}>
                    <DetailsContent<SERVICE_DETAILS_FEE_KEYS, IFeeObjectAllData>
                        title="Внутрішня комісія"
                        onSetUpdatedData={handleUpdateServiceFeeInnerData}
                        setDetailsData={setServiceDetailFeeInnerData}
                        detailsData={serviceDetailFeeInnerData}
                        detailsTitles={SERVICE_DETAILS_FEE_TITLES}
                        lockedFields={SERVICES_LOCKED_FEE_FIELDS}
                    />
                    <DetailsContent<SERVICE_DETAILS_FEE_KEYS, IFeeObjectAllData>
                        title="Внутрішня мін. та макс. сума платежу"
                        onSetUpdatedData={handleUpdateServiceFeeInnerData}
                        setDetailsData={setServiceDetailFeeInnerData}
                        detailsData={serviceDetailFeeInnerData}
                        detailsTitles={SERVICE_DETAILS_SUM_TITLES}
                        lockedFields={SERVICES_LOCKED_FEE_FIELDS}
                    />
                    <Box className={s.actions}>
                        <Button
                            startIcon={<Save opacity={serviceUpdatedFeeInnerData === null ? 0.3 : 1}/>}
                            variant="outlined"
                            disabled={serviceUpdatedFeeInnerData === null}
                            onClick={() => handleSubmitUpdateFeeService('INNER')}
                        >
                            Зберегти
                        </Button>
                    </Box>
                </Box>
            }
            {serviceDetailFeeOuterData &&
                <Box className={s.fee}>
                    <DetailsContent<SERVICE_DETAILS_FEE_KEYS, IFeeObjectAllData>
                        title="Зовнішня комісія"
                        onSetUpdatedData={handleUpdateServiceFeeOuterData}
                        setDetailsData={setServiceDetailFeeOuterData}
                        detailsData={serviceDetailFeeOuterData}
                        additionalData={serviceDetailFeeInnerData}
                        detailsTitles={SERVICE_DETAILS_FEE_TITLES}
                        lockedFields={getLockedFields(SERVICE_DETAILS_FEE_TITLES, canI)}
                    />
                    <DetailsContent<SERVICE_DETAILS_FEE_KEYS, IFeeObjectAllData>
                        title="Зовнішня мін. та макс. сума платежу"
                        onSetUpdatedData={handleUpdateServiceFeeOuterData}
                        setDetailsData={setServiceDetailFeeOuterData}
                        additionalData={serviceDetailFeeInnerData}
                        detailsData={serviceDetailFeeOuterData}
                        detailsTitles={SERVICE_DETAILS_SUM_TITLES}
                        lockedFields={getLockedFields(SERVICE_DETAILS_SUM_TITLES, canI)}
                    />
                    <Box className={s.actions}>
                        <Button
                            startIcon={<Save opacity={serviceUpdatedFeeOuterData === null ? 0.3 : 1}/>}
                            variant="outlined"
                            disabled={serviceUpdatedFeeOuterData === null}
                            onClick={() => handleSubmitUpdateFeeService('OUTER')}
                        >
                            Зберегти
                        </Button>
                    </Box>
                </Box>
            }
        </Box>
    );
};
