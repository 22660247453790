import { API } from 'api';
import { AxiosResponse } from 'axios';
import { API_URL } from 'utils/enums';
import { IPaymentListRequest } from "types/payment.interface";

class PaymentService {
    static getPaymentList = async (reqBody: IPaymentListRequest): Promise<AxiosResponse> =>
        API.post(API_URL.PAYMENT_LIST_GET, reqBody);

    static getPaymentBillInfo = async (billId: string): Promise<AxiosResponse> =>
        API.post(API_URL.PAYMENT_BILL_INFO, { billId });
}

export { PaymentService };
