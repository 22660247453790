import { API } from 'api';
import { AxiosResponse } from 'axios';
import {
	IGetUserDetailsResponse,
	IRequestDeleteCard,
	IRequestUpdateUserStatus,
	IUpdateUserRequest,
	IUserCardsListResponse,
	IUserDetailsApartmentsResponse,
	IUsersRequest,
	IUsersResponse,
} from 'types';
import { API_URL } from 'utils/enums';
import { APARTMENT_REQUEST, CARD_REQUEST } from '../constants';

class UsersService {
	static getUsersList = async (reqBody: IUsersRequest): Promise<AxiosResponse<IUsersResponse>> =>
		API.post(API_URL.USERS_LIST_GET, reqBody);

	static getUserById = async (id: string): Promise<AxiosResponse<IGetUserDetailsResponse>> =>
		API.post(API_URL.USER_DETAILS_GET, { userId: id });

	static updateUserById = async (reqBody: IUpdateUserRequest): Promise<AxiosResponse> => {
		const { userId, ...body } = reqBody;
		return API.post(API_URL.USER_UPDATE, { userId, dataForUpdate: body });
	};

	static deleteUserById = async (userId: string, type: string): Promise<AxiosResponse<IGetUserDetailsResponse>> =>
		API.post(API_URL.USER_DELETE, { userId, type });

	static createUser = async (reqBody: IUpdateUserRequest): Promise<AxiosResponse<IGetUserDetailsResponse>> =>
		API.post(API_URL.USER_CREATE, { ...reqBody, locale: 'UK', clientCodeName: 'hostpay' });

	static getApartment = async (userId: string): Promise<AxiosResponse<IUserDetailsApartmentsResponse>> =>
		API.post(API_URL.USER_APARTMENT_GET, {
			...APARTMENT_REQUEST,
				options: {
					userId,
				},
			},
		);

	static getCardList = async (userId: string): Promise<AxiosResponse<IUserCardsListResponse>> =>
		API.post(API_URL.USER_GARD_LIST_GET, { ...CARD_REQUEST, userId });

	static deleteCardById = async (reqBody: IRequestDeleteCard): Promise<AxiosResponse<any>> =>
		API.post(API_URL.USER_GARD_DELETE, reqBody);

	static updateUserStatusBlock = async (reqBody: IRequestUpdateUserStatus): Promise<AxiosResponse> =>
		API.post(API_URL.USER_UPDATE_STATUS_BLOCK, reqBody);

	static updateUserStatusBlockTemporary = async (reqBody: IRequestUpdateUserStatus): Promise<AxiosResponse> =>
		API.post(API_URL.USER_UPDATE_STATUS_BLOCK_TEMPORARY, reqBody);
}

export { UsersService };
