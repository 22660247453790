import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import { AxiosResponse } from 'axios';
import { ROUTES } from 'routes';
import { IQuery } from 'types';
import { formatQueryString, parseQueryString } from 'utils/helpers';
import { AddressesService } from 'utils/services';
import s from './styles.module.scss';

const SearchBar: FC = () => {
	const navigate = useNavigate();
	const { search, pathname } = useLocation();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);
	const [searchQuery, setSearchQuery] = useState<string>(query.searchQuery || query.street || '');
	const [streetsOptions, setStreetsOptions] = useState<string[]>([]);

	const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false);
	const isSingleReceiptsPage = pathname === `/${ROUTES.singleReceipts}`;

	useEffect(() => {
		setSearchQuery('');
	}, [pathname]);

	useEffect(() => {
		if(!query.searchQuery) {
			setSearchQuery('');
		}
	}, [query.searchQuery]);

	const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchQuery(event.target.value);
	};

	const handleSearchButtonClick = (fieldName: string, value: string): void => {
		let formattedPhoneNumber = value.trim();

		const isNumeric = /^\d+$/.test(formattedPhoneNumber);

		if (value.startsWith('+380')) {
			formattedPhoneNumber = value.replace('+380', '380');
		} else if (value.startsWith('0') && isNumeric) {
			formattedPhoneNumber = `380${  value.substring(1)}`;
		}
		navigate(
			{
				search: formatQueryString({
					...query,
					[fieldName]: formattedPhoneNumber,
					page: 0,
					house: null,
					flat: null,
				}),
			},
			{ replace: true }
		);
	};

	const handleSearchStreets = async (value: string): Promise<void> => {
		try {
			setIsAutocompleteLoading(true);
			const reqBody = {
				street: value,
			};
			const response: AxiosResponse<string[]> = await AddressesService.getStreetsWithType(reqBody);
			setStreetsOptions(response.data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsAutocompleteLoading(false);
		}
	};

	const handleAutocompleteChange = (value: string) => {
		setSearchQuery(value);
		handleSearchButtonClick('street', value);
	};

	return pathname === `/${ROUTES.addresses}` ? (
		<Box sx={{ width: '400px' }}>
			<Autocomplete
				noOptionsText="Не знайдено"
				options={streetsOptions}
				renderInput={(params) => <TextField label="Пошук..." {...params} />}
				getOptionLabel={(option: string) => option}
				loading={isAutocompleteLoading}
				onInputChange={(_, value) => handleSearchStreets(value)}
				onChange={(_, value) => handleAutocompleteChange(value || '')}
				sx={{
					'& .MuiOutlinedInput-root': {
						padding: 0,
					},
				}}
				value={query.street || ''}
			/>
		</Box>
	) : (
		<>
			<TextField
				className={s.input}
				sx={{
					'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
						display: 'none',
					},
				}}
				InputProps={{
					startAdornment: <SearchIcon />,
				}}
				type={isSingleReceiptsPage ? 'number' : ''}
				value={searchQuery}
				variant="outlined"
				placeholder={isSingleReceiptsPage ? 'Наприклад: 6985790' : 'Пошук...'}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						handleSearchButtonClick('searchQuery', searchQuery);
					}
				}}
				onChange={handleSearchInputChange}
			/>
			<Button variant="contained" onClick={() => handleSearchButtonClick('searchQuery', searchQuery)}>
				Пошук
			</Button>
		</>
	);
};

export default SearchBar;
