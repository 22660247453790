export const CLIENTS_HEADER_TITLES = {
	ID: 'ID',
	NAME: 'Імʼя',
	EMAIL: 'Email',
	UPDATED_AT: 'Остання активність',
};

export const CREATE_CLIENT = {
	name: '',
	codeName: '',
	siteUrl: '',
};