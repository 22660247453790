import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as UploadIcon } from 'assets/icons/document-upload.svg';
import { ReactComponent as DeleteFileIcon } from 'assets/icons/close-circle.svg';
import { useFormikContext } from 'formik';
import { IParserForm } from 'pages/DataSettingsPage/DataSettings/DataSettingForm/config';
import styles from './index.module.scss';

interface IUploadFile {
	title: string;
	fieldName: keyof IParserForm;
	fileFormat: string;
	isDisabled: boolean;
}

export const UploadFile: FC<IUploadFile> = ({ fieldName, title, fileFormat, isDisabled }) => {
	const { values, setFieldValue } = useFormikContext<IParserForm>();

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];

		if (fieldName && file) {
			setFieldValue(fieldName, file);
		}
	};

	const handleClearFile = () => {
		setFieldValue(fieldName, null);
	};

	return (
		<Box className={styles.wrapper}>
			<Typography variant="body2" color={isDisabled ? 'text.disabled' : 'text.primary'}>
				{title}
			</Typography>
			{values[fieldName] ? (
				<Box
					className={styles.file_name}
					sx={{
						opacity: isDisabled ? 0.3 : 1,
					}}
				>
					<Typography variant="button">{(values[fieldName] as File).name}</Typography>
					{/*<Button aria-label={fieldName} component="span" disabled={isDisabled}>*/}

					<Button aria-label={fieldName} component="span" >
						<DeleteFileIcon onClick={handleClearFile} />
					</Button>
				</Box>
			) : (
				<label htmlFor={fieldName}>
					<input
						id={fieldName}
						name={fieldName}
						accept={fileFormat}
						disabled={isDisabled}
						type="file"
						onChange={handleFileChange}
						style={{ display: 'none' }}
					/>
					<Button aria-label={fieldName} component="span" disabled={isDisabled}>
						<UploadIcon title={fileFormat} opacity={isDisabled ? 0.3 : 1} />
					</Button>
				</label>
			)}
		</Box>
	);
};
