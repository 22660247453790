import { FC } from 'react';
import s from './styles.module.scss';
import { Box, CircularProgress, Typography } from '@mui/material';

const Loading: FC = () => {
	return (
		<Box className={s.wrapper}>
			<Typography variant="h4">Завантаження...</Typography>
			<CircularProgress color="success" />
		</Box>
	);
};

export default Loading;
