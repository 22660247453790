import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from 'utils/enums';

export const formatDateView = (
	date: Dayjs | number | string,
	format: DATE_FORMAT | string = DATE_FORMAT.DATE_VIEW
): string => {
	if (date === null || date === undefined) {
		return '';
	} else {
		return dayjs(date).format(format);
	}
}


export const getDiffTimeString = (miliseconds: number): string => {
	const seconds = Math.floor((miliseconds / 1000) % 60);
	const minutes = Math.floor((miliseconds / 1000 / 60) % 60);
	return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
