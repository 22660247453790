import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export const getMonthOptions = (selectedYear: string) => {
	const currentYear = dayjs().year();
	const currentMonth = dayjs().month() + 1;

	let startMonth: number, endMonth: number;

	if (selectedYear === currentYear.toString()) {
		startMonth = 1;
		endMonth = currentMonth - 1;
	} else if (selectedYear === '2023') {
		startMonth = 11;
		endMonth = 12;
	} else {
		startMonth = 1;
		endMonth = 12;
	}
	const months = dayjs
		.months()
		.slice(startMonth - 1, endMonth)
		.map((monthName, index) => ({
			title: monthName,
			value: dayjs()
				.month(startMonth + index - 1)
				.format('MM'),
		}));

	return months;
};

export const getYearOptions = () => {
	const dataStartYear = 2023;

	const currentYear = dayjs().year();
	const availableYears = Array.from({ length: currentYear - dataStartYear + 1 }, (_, index) => dataStartYear + index);

	const yearOptions = availableYears.map((year) => ({
		title: year.toString(),
		value: year.toString(),
	}));

	return yearOptions;
};
