interface AnyObject {
	[key: string]: any;
}
export const removePrefix = (obj: AnyObject, prefix: string): AnyObject => {
	const newObj: AnyObject = {};
	for (let key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const newKey = key.startsWith(prefix) ? key.slice(prefix.length) : key;
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				newObj[newKey] = removePrefix(obj[key], prefix);
			} else {
				newObj[newKey] = obj[key];
			}
		}
	}
	return newObj;
};
