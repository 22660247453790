import { FC } from 'react';
import { Box, Stack } from '@mui/system';
import styles from './index.module.scss';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const ForgotPasswordPage: FC = () => (
	<div className={styles.layout}>
		<Box className={styles.card}>
			<Stack className={styles.content}>
				<ForgotPasswordForm />
			</Stack>
		</Box>
	</div>
);
