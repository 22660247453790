import { PROVIDER_TYPE, SINGLE_RECEIPT_DETAILS_KEYS } from 'utils/enums';

export const SINGLE_RECEIPTS_HEADER_TITLES = {
	ID: 'Номер ЄК',
	PAYMENT_TYPE: 'Тип платежу',
	APARTMENT_ACCOUNT_ID :'Host ID',
	CITY: 'Місто',
	ADDRESS: 'Адреса',
	STATUS: 'Стан',
	BALANCE: 'Баланс',
	CREATED_AT: 'Дата',
};

export const PAYMENT_STATUS_TITLE = {
	PAID: 'Сплачено',
	UNPAID: 'Борг',
	ALL: 'Всі',
};

export const SINGLE_RECEIPT_LOCKED_FIELDS = [
	SINGLE_RECEIPT_DETAILS_KEYS.PAYMENT_TYPE,
	SINGLE_RECEIPT_DETAILS_KEYS.ID,
	SINGLE_RECEIPT_DETAILS_KEYS.CITY,
	SINGLE_RECEIPT_DETAILS_KEYS.STREET,
	SINGLE_RECEIPT_DETAILS_KEYS.BALANCE,
];

export const PROVIDER_SORT_ORDER = [PROVIDER_TYPE.HCS, PROVIDER_TYPE.KVD, PROVIDER_TYPE.KTS, PROVIDER_TYPE.KVBO];
