import { Box, Button, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IQuery } from 'types';
import { parseQueryString } from 'utils/helpers';
import { ReactComponent as HostMain } from 'assets/images/host-main.svg';
import styles from './index.module.scss';
import { initialValues, validationSchema } from './config';
import { useAppDispatch } from 'store/hooks';
import { userPasswordRecoveryReset, userSetPassword } from 'store/user';
import { ROUTES } from 'routes';
import { StorageService } from 'utils/services';
import { STORAGE_KEY } from 'utils/enums';

export const SetPasswordForm: FC = () => {
	const { search } = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);
	const { code, sessionId, isRecovery } = query;

	const handleSubmit = (values: any) => {
		if (isRecovery) {
			dispatch(userPasswordRecoveryReset({ newPassword: values.password, code: code?.toString(), sessionId }));
			StorageService.set(STORAGE_KEY.IS_PASSWORD_CHANGED, true);
		} else {
			StorageService.set(STORAGE_KEY.SESSION_ID, sessionId);
			dispatch(userSetPassword({ newPassword: values.password, sessionId }));
		}
		navigate(`/${ROUTES.login}`);
	};

	return (
		<Box className={styles.wrapper}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				validateOnChange
				onSubmit={handleSubmit}
			>
				{({ errors, touched }) => (
					<Form>
						<Box className={styles.form}>
							<HostMain />
							<Typography variant="h4" color="text.primary" className={styles.info}>
								{isRecovery ? 'Відновлення ' : 'Встановлення '}пароля
							</Typography>
							<Field
								as={TextField}
								sx={{
									'& input[type="password"]': {
										fontFamily: 'Verdana',
										letterSpacing: '0.15px',
									},
								}}
								name="password"
								label="Новий пароль"
								type="password"
								error={errors.password && touched.password}
							/>

							<Field
								as={TextField}
								sx={{
									'& input[type="password"]': {
										fontFamily: 'Verdana',
										letterSpacing: '0.15px',
									},
								}}
								name="confirmPassword"
								label="Підтвердження пароля"
								type="password"
								error={errors.confirmPassword && touched.confirmPassword}
							/>
						</Box>
						<Box display="flex">
							<Button type="submit" variant="rounded" color="success">
								Продовжити
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
