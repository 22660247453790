import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import dayjs from 'dayjs';
import { downloadReceipt } from 'pages/SingleReceiptsPage/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getReportListById, getReportsListSelector } from 'store/reports';
import { getSingleReceiptById, getSingleReceiptDetailsSelector } from 'store/single-receipts';
import { IReceiptDetails, IReportsDataList } from 'types';
import { DetailsContent } from 'components/modules/DetailsContent';
import Loading from 'components/modules/Loading';
import { SINGLE_RECEIPT_LOCKED_FIELDS } from 'utils/constants';
import { SINGLE_RECEIPT_DETAILS_KEYS } from 'utils/enums';
import { convertCoinsToUAH, getLocaleAmountString } from 'utils/helpers';
import { DETAILS_TITLES, downloadReportPdf } from './config';
import styles from './index.module.scss';

export const SingleReceiptDetailsPage: FC = () => {
	const dispatch = useAppDispatch();
	const { id } = useParams();

	const singleReceipt = useAppSelector(getSingleReceiptDetailsSelector);
	const reportList = useAppSelector(getReportsListSelector);

	useEffect(() => {
		if (id) {
			dispatch(getSingleReceiptById(+id));
			dispatch(getReportListById(+id));
		}
	}, [id, dispatch]);

	const handleClickDownload = (): void => {
		if (singleReceipt) {
			downloadReceipt(singleReceipt);
		}
	};


	const handleDownloadReport = (report: IReportsDataList) =>  {
		if (report) {
			downloadReportPdf(report);
		}
	};

	const modifiedSingleReceipt = useMemo(() => {
		if (!singleReceipt) return null;

		const modifiedData: IReceiptDetails = {
			paymentType: 'Комунальний платіж',
			city: singleReceipt.apartmentLocation?.city ?? 'М.ХАРКІВ',
			street: `${singleReceipt.apartmentLocation.address} ${singleReceipt.apartmentLocation.flat ? ',кв' : ''}  ${
				singleReceipt.apartmentLocation.flat
			}`,
			id: singleReceipt.id,
			balance: getLocaleAmountString(convertCoinsToUAH(singleReceipt.balance || '')) || null,
		};
		return modifiedData;
	}, [singleReceipt]);

	if (!modifiedSingleReceipt) return <Loading/>;

	return (
		<Box className={styles.wrapper}>
			<Box className={styles.container}>
				<DetailsContent<SINGLE_RECEIPT_DETAILS_KEYS, IReceiptDetails>
					detailsTitles={DETAILS_TITLES}
					title="Дані"
					detailsData={modifiedSingleReceipt}
					lockedFields={SINGLE_RECEIPT_LOCKED_FIELDS}
				/>
				<Box className={styles.actions}>
					<Button variant="outlined" startIcon={<Download />} onClick={handleClickDownload}>
						Завантажити єдину квитанцію
					</Button>
				</Box>
			</Box>
			<Box className={styles.list}>
				{reportList &&
					reportList.map((report: IReportsDataList) => {
						return (
							<Button className={styles.button} variant="outlined" onClick={() => handleDownloadReport(report)} key={report.id}>
								{`Квитанція оплати ${dayjs(report.createdAt).format('DD.MM.YY HH:mm')}`}
							</Button>
						);
					})
				}
			</Box>
		</Box>
	);
};
