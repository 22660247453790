import { createSlice } from '@reduxjs/toolkit';
import { getRejectedDataList } from './actions';
import { IRejectedDataReducer } from 'types';

const initialState: IRejectedDataReducer = {
	isLoading: true,
	data: null,
};

const rejectedDataSlice = createSlice({
	name: 'rejectedDataSlice',
	initialState,
	reducers: {
		clearRejectedData: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getRejectedDataList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getRejectedDataList.fulfilled, (state, { payload }) => {
			state.data = payload;
			state.isLoading = false;
		});
		builder.addCase(getRejectedDataList.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { clearRejectedData } = rejectedDataSlice.actions;
export const rejectedDataReducer = rejectedDataSlice.reducer;
