import {CustomSelect, CustomSelectProps} from "../CustomSelect";
import {FC} from "react";
import {FieldInputProps} from "formik";

interface CustomSelectFormikProps extends CustomSelectProps {
    field: FieldInputProps<string>
}

export const CustomSelectFormik: FC<CustomSelectFormikProps> = ({ field, ...props}) => {
    return <CustomSelect {...props} {...field} />
}