import { FC } from 'react';
import { ReactComponent as HostMain } from 'assets/images/host-main.svg';
import { Box, Stack, Typography } from '@mui/material';
import { LoginForm } from 'pages/Login/LoginForm';
import styles from './index.module.scss';

export const LoginPage: FC = () => (
	<div className={styles.layout}>
		<Box className={styles.card}>
			<Stack className={styles.content}>
				<HostMain />
				<Typography variant="h3" className={styles.content__title}>
					Вхід
				</Typography>
				<LoginForm />
			</Stack>
		</Box>
	</div>
);
