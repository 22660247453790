import { FC } from "react";
import { FieldInputProps, useField } from "formik";
import { CustomDatePicker, CustomDatePickerProps } from "../CustomDatePicker";

interface CustomDatePickerFormikProps extends CustomDatePickerProps {
    field: FieldInputProps<string>
}

export const CustomDatePickerFormik: FC<CustomDatePickerFormikProps> = ({ field, ...props }) => {
    const [, , { setValue }] = useField(field)
    const handleChangeDate = (value: Date | null) => {
        setValue(value)
    }
    return <CustomDatePicker {...props} {...field}  onChange={handleChangeDate} />
}
